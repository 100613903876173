import AppLayout from "../components/AppLayout";

const Contact = () => {
  return (
    <AppLayout>
      <div>
        <h2>Contact Us</h2>
        <p>Email us at: example@example.com</p>
      </div>
    </AppLayout>
  );
};

export default Contact;
