import { createSlice } from '@reduxjs/toolkit';
import { translateOne } from '../libs/utils';

const initialState = {
    results: [],
    filters: {
        stars: [],
        services: [],
        localities: [],
    }
};

const resultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setResults: (state, action) => {
            state.results = action.payload;
        },
        clearResults: (state) => {
            state.results = [];
        }
    }
});

function containsSubArray(mainArray, subArray) {
    return subArray.every(val => mainArray.includes(val));
  }

export const { setResults, clearResults, setFilters } = resultsSlice.actions;

export const selectResults = state => state.results.results;
export const selectFilteredResults = state => {
    return state.results.results.filter((result) => {
        let res = (state.results.filters.stars.length === 0 || state.results.filters.stars.includes(result.stars+"") ) &&
        (state.results.filters.localities.length === 0 || state.results.filters.localities.includes(result.city) ) &&
        (state.results.filters.services.length === 0 || containsSubArray(result.facility_services.map(v => translateOne(v).name), state.results.filters.services)) 

        return res
        
    });
}

export const selectFilters = state => state.filters;


export default resultsSlice.reducer;
