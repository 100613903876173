import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StyledLink } from "./StyledNav";
import { selectUser } from "../redux/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "./LanguageSwitch";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Avatar, Grid, AppBar, Toolbar, Container } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import { RedButton } from "./ui/button-styles";

const Navbar = () => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSticky, setIsSticky] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 10) {
        // Change this value to adjust when the class should be added
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <AppBar
      className={isSticky ? "header-sticky" : ""}
      position="static"
      // sx={{
      //   zIndex: "9000000 !important",
      // }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid
            container
            component="header"
            p={{ xs: 1, sm: 2, md: 4 }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item display={{ xs: "none", md: "block" }}>
              <Link to={"/"}>
                <img
                  className="cursor-pointer"
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  alt="Logo"
                ></img>
              </Link>
            </Grid>
            <Grid item display={{ xs: "block", md: "none" }}>
              <Link to={"/"}>
                <img
                  src={`${process.env.PUBLIC_URL}/logo512.png`}
                  alt="Logo"
                  style={{
                    height: 59,
                    width: 69,
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                ></img>
              </Link>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item display={{ xs: "none", md: "block" }}>
                  <StyledLink
                    isactive={(location.pathname === "/").toString()}
                    to="/"
                  >
                    {t("HOME")}
                  </StyledLink>
                </Grid>
                <Grid item>
                  <LanguageSwitch />
                </Grid>
                <Grid item>
                  {user ? (
                    <>
                      <IconButton
                        aria-controls="user-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        edge="end"
                      >
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                        <span style={{ marginLeft: 8, color: "black" }}></span>
                      </IconButton>

                      <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to="/profile"
                        >
                          {t("Profilo")}
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to="/my-orders"
                        >
                          {t("I miei ordini")}
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to="/change-password"
                        >
                          {t("Cambio password")}
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to="/logout"
                        >
                          {t("Esci")}
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <Link
                      isactive={(location.pathname === "/access").toString()}
                      to="/access"
                    >
                      <RedButton variant="contained" size="large">
                        {t("Accedi")}
                      </RedButton>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
