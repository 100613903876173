import React from "react";
import Stars from "./Stars";
import { useNavigate } from "react-router-dom";
import { useStore } from "react-redux";
import { diffDays, formatEuro } from "../libs/utils";
import { useTranslation } from "react-i18next";

function HotelCard({ hotel }) {
  const app = useStore().getState().app;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notti_soggiorno, setNottiSoggiorno] = React.useState(0);
  const useNavigateToRoute = (route) => {
    // Format ages for and url query using ages[]=1 and ages[]=2 according to the ages of the children
    const ages = app.childrenAges.map((age) => `ages[]=${age}`).join("&");

    navigate(
      `/hotel/${hotel.id}?from=${app.from}&to=${app.to}&adults=${app.adults}&children=${app.children}&${ages}&couponCode=${app.couponCode}`
    );
  };

  React.useEffect(() => {
    setNottiSoggiorno(diffDays(new Date(app.from), new Date(app.to)));
  }, [app]);

  return (
    <div
      className="flex flex-row overflow-auto bg-white cursor-pointer rounded-xl hotel-card marker-card"
      onClick={useNavigateToRoute}
    >
      <div
        style={{
          backgroundImage: `url("${
            process.env.REACT_APP_BASE_URL + hotel.image?.formats.medium.url
          }")`,
        }}
        className="h-full bg-no-repeat hotel-card__image"
      ></div>
      {/*<img src={process.env.REACT_APP_BASE_URL + hotel.image?.formats.medium.url} alt={hotel.businessName} className="hotel-card__image" />*/}
      <div className="card-details">
        <div className="hotel-card__stars">
          <Stars count={hotel.stars}></Stars>
        </div>
        <h3 className="hotel-card__name">{hotel.businessName}</h3>
        <p className="hotel-card__address">
          <img
            className="map-img leaflet-popup-map-icon"
            alt="map-icon"
            src="/images/map-pin.svg"
          />
          {hotel.address}
        </p>
        {/* <p className="hotel-card__description">{hotel.description}</p> */}
        <p className="person-info">
          {notti_soggiorno} {t("_NIGHT_LC_", { count: notti_soggiorno })},{" "}
          {app.adults} {t("_ADULTS_LC_", { count: app.adults })} {app.children}{" "}
          {t("_CHILDREN_LC_", { count: app.children })}
        </p>
        {hotel.request_only ? (
          <p className="hotel-card__price">{t("request_now")}</p>
        ) : (
          <p className="hotel-card__price">
            {t("_FROM_UCF_")} € {hotel?.cheapest?.price}
          </p>
        )}
      </div>
    </div>
  );
}

export default HotelCard;
