import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockResetIcon from "@mui/icons-material/LockReset";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";

const BasicList = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(selectUser);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();
  const language = window.localStorage.getItem("language");

  return (
    <Box
      className="profile-box-wrap"
      sx={{
        p: 3,
        borderRadius: "24px",
        bgcolor: "background.paper",
        border: "2px solid #DCDCDC",
        position: "sticky",
        top: 40,
        left: 0,
      }}
    >
      <List subheader={<ListItem component="div">{user?.username}</ListItem>}>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={handleClose} component={Link} to="/profile">
            <ListItemIcon>
              <PermIdentityIcon />
            </ListItemIcon>
            <ListItemText primary={t("my_profile")} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={handleClose}
            component={Link}
            to="/my-orders"
          >
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText primary={t("my_bookings")} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={handleClose}
            component={Link}
            to="/change-password"
          >
            <ListItemIcon>
              <LockResetIcon />
            </ListItemIcon>
            <ListItemText primary={t("change_password")} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={handleClose} component={Link} to="/logout">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("logout")} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default BasicList;
