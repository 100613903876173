import { useEffect } from "react";

import AppLayout from "../components/AppLayout";

import { useNavigate } from "react-router-dom";
import authService from "../services/api.service";
import { setUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    authService.logout();
    dispatch(setUser(null));
    navigate("/");
  }, [dispatch, navigate]);

  return (
    <AppLayout>
      <div></div>
    </AppLayout>
  );
};

export default Logout;
