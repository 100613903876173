import styled from "styled-components";

import AppLayout from "../components/AppLayout";

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
`;

const List = styled.ul`
  margin-top: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const ThingsToDo = () => {
  return (
    <AppLayout>
      <Container>
        <Title>Things To Do</Title>
        <List>
          <ListItem>Visit the local museum.</ListItem>
          <ListItem>Take a walk in the city park.</ListItem>
          <ListItem>Try the famous street food.</ListItem>
          <ListItem>Join a city tour.</ListItem>
          <ListItem>Visit the historic downtown area.</ListItem>
        </List>
      </Container>
    </AppLayout>
  );
};

export default ThingsToDo;
