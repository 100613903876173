import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import authService from "../services/api.service";
import { setUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ApiService from "../services/api.service";

const RegisterComponent = ({ checkout }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required(t("Campo richiesto")),
    password: yup
      .string()
      .required(t("Campo richiesto"))
      .min(6, t("Minimo 6 caratteri")),
    firstName: yup.string().required(t("Campo richiesto")),
    lastName: yup.string().required(t("Campo richiesto")),
    gdpr_accepted: yup.boolean().oneOf([true], t("Accetta la privacy")),
    messages_accepted: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gdpr_accepted: false,
      messages_accepted: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        values.username = values.firstName + " " + values.lastName;

        const data = await authService.register(values);
        localStorage.setItem("token", data.jwt);
        ApiService.apiClient.defaults.headers[
          "Authorization"
        ] = `Bearer ${data.jwt}`;
        dispatch(setUser(data.user));
        // navigate to home or to checkout
        checkout ? navigate("/checkout") : navigate("/");
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h5 className="form-title">{t("Inserisci i tuoi dati")}</h5>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="firstName"
            label={t("Nome")}
            name="firstName"
            {...formik.getFieldProps("firstName")}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && t(formik.errors.firstName)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="lastName"
            label={t("Cognome")}
            name="lastName"
            {...formik.getFieldProps("lastName")}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && t(formik.errors.lastName)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            {...formik.getFieldProps("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && t(formik.errors.email)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="password"
            {...formik.getFieldProps("password")}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && t(formik.errors.password)}
          />
        </Grid>
        <Grid item xs={12} className="checkbox-form-wrap checkbox-top">
          {/* <div id="acconsento-terms-container"></div> */}
          <FormControlLabel
            control={
              <Checkbox
                id="gdpr_accepted"
                color="primary"
                {...formik.getFieldProps("gdpr_accepted")}
              />
            }
            label={t("_APPROVAZIONE_PRIVACY_")}
          />
          {formik.touched.gdpr_accepted &&
            Boolean(formik.errors.gdpr_accepted) && (
              <div style={{ color: "red" }}>{formik.errors.gdpr_accepted}</div>
            )}
        </Grid>
        <Grid item xs={12} className="checkbox-form-wrap">
          <FormControlLabel
            control={
              <Checkbox
                id="messages_accepted"
                color="primary"
                {...formik.getFieldProps("messages_accepted")}
              />
            }
            label={t("_ACCEPT_PROMOTIONAL_MESSAGES_")}
          />
        </Grid>
        <Grid item xs={12} md={6} className="submit-btn-wrap">
          <Button type="submit" variant="search" fullWidth>
            {t("Continua")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterComponent;
