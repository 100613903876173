import React from "react";
import Stars from "./Stars";
import { useNavigate } from "react-router-dom";
import { useStore } from "react-redux";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { diffDays, formatEuro, translateOne } from "../libs/utils";

function HotelCard({ hotel }) {
  const { t } = useTranslation();
  const app = useStore().getState().app;
  const navigate = useNavigate();
  const [notti_soggiorno, setNottiSoggiorno] = React.useState(0);

  const useNavigateToRoute = (route) => {
    // Format ages for and url query using ages[]=1 and ages[]=2 according to the ages of the children
    const ages = app.childrenAges.map((age) => `ages[]=${age}`).join("&");

    navigate(
      `/hotel/${hotel.id}?from=${app.from}&to=${app.to}&adults=${app.adults}&children=${app.children}&${ages}&couponCode=${app.couponCode}`
    );
  };

  React.useEffect(() => {
    setNottiSoggiorno(diffDays(new Date(app.from), new Date(app.to)));
  }, [app]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#fff",
        borderRadius: "24px",
        border: "2px solid #e5e8f3",
        marginTop: "29px",
        overflow: "hidden",
        minHeight: 300,
      }}
    >
      <Grid
        item
        xs={12}
        minHeight={{ xs: 450, md: 0 }}
        md={4}
        sx={{
          backgroundImage: `url("${
            process.env.REACT_APP_BASE_URL + hotel.image?.formats?.medium.url
          }")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></Grid>

      {/*<img src={process.env.REACT_APP_BASE_URL + hotel.image?.formats.medium.url} alt={hotel.businessName} className="hotel-card__image" /> */}
      <Grid
        item
        xs={12}
        md={8}
        pt={3}
        pb={3}
        pl={4}
        pr={4}
        container
        flexDirection="column"
        alignItems="left"
        justifyContent="space-between"
        className="card-wrap"
      >
        <Grid item>
          <div className="hotel-card__stars" style={{ marginBottom: "8px" }}>
            <Stars count={hotel.stars}></Stars>
          </div>
          {/* <Rating value={hotel.stars} readOnly /> */}
          <h3 className="hotel-card__name">{hotel.businessName}</h3>
          <p className="hotel-card__address address-with-pin">
            <img className="map-img" alt="map-icon" src="/images/map-pin.svg" />{" "}
            {hotel.address}
          </p>
        </Grid>
        {/* dettaglio best result */}
        <Grid item mt={{ sm: 2, md: 0 }}>
          {!hotel.request_only && (
            <p className="hotel-card__result">
              {
                translateOne(
                  hotel?.accommodations.find((item) => {
                    return item.id == hotel?.cheapest?.accommodation;
                  })
                ).name
              }
            </p>
          )}
          {/*<p className="hotel-card__result">
            accommodation: {hotel?.cheapest?.accommodation}, rate plan:{" "}
            {hotel?.cheapest?.rate_plan}
          </p>*/}
          {!hotel.request_only && (
            <p className="hotel-card__result">
              {notti_soggiorno} {t("_NIGHT_LC_", { count: notti_soggiorno })},{" "}
              {app.adults} {t("_ADULTS_LC_", { count: app.adults })}{" "}
              {app.children} {t("_CHILDREN_LC_", { count: app.children })}
            </p>
          )}
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="book-btn-wrap"
          >
            <Grid item>
              {!hotel.request_only && (
                <p className="hotel-card__price">
                  {t("_FROM_UCF_")} €{" "}
                  <span className="price">
                    {formatEuro(hotel?.cheapest?.price.toFixed(2))}
                  </span>
                </p>
              )}
            </Grid>
            <Grid item>
              {hotel.request_only ? (
                <Button variant="requestNow" onClick={useNavigateToRoute}>
                  {t("Richiedi ora")}
                </Button>
              ) : (
                <Button variant="bookNow" onClick={useNavigateToRoute}>
                  {t("Prenota ora")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HotelCard;
