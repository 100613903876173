// HotelsList.js
import React from "react";
import HotelCard from "./hotel-card-new";
import { Box } from "@mui/system";

function HotelsList({ hotels }) {
  return (
    <Box className="hotels-list">
      {hotels.map((hotel) => (
        <HotelCard key={hotel.id} hotel={hotel} />
      ))}
    </Box>
  );
}

export default HotelsList;
