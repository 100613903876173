import React, { useState, useEffect } from "react";

import { Grid, Card, Typography, Button } from "@mui/material";

import PrezzoExtra from "./ui/prezzo-extra";
import LoginOrRegister from "./LoginOrRegister";
import CheckoutForm from "./payment/CheckoutForm";

import apiService from "../services/api.service";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectExtras } from "../redux/appSlice";
import { selectUser } from "../redux/userSlice";
import { resolveUrl } from "../libs/utils";
import { useDispatch } from "react-redux";
import { setExtras } from "../redux/appSlice";
import { useCart } from "react-use-cart";
import { toast } from "react-toastify";

function BillingInfo() {
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    metadata,
  } = useCart();

  const { t: translate } = useTranslation();
  const extra = useSelector(selectExtras);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [stripeInitiated, setStripeInitiated] = useState(false);
  const [numItems, setNumItems] = useState(0);
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    setNumItems(items.filter((item) => !item.tmp).length);
  }, [items]);

  const removeExtra = (e) => {
    const newExtras = extra.filter((item) => item.id !== e.id);
    dispatch(setExtras(newExtras));
  };

  const onRemoveItem = (e) => {
    removeItem(e.id);
  };

  return (
    <>
      {user == null && (
        <Grid
          container
          justifyContent="center"
          item
          xs={12}
          className="login-register-title-wrap"
        >
          <Card
            sx={{ p: 3, width: "100%" }}
            elevation={0}
            className="title-main-wrap"
          >
            <Typography
              className="purchase-form-title"
              variant="h6"
              sx={{ my: 2, textAlign: "center" }}
            >
              {/* {translate("login_or_register_to_purchase")} */}
            </Typography>
          </Card>
          <LoginOrRegister
            /*onComplete={() => {
              toast.success(translate("login_success"));
            }}
            onError={(error) => {
              toast.error(translate("login_error"));
            }}*/
            checkout={true}
          />
        </Grid>
      )}
      {user != null && (
        <Grid
          container
          sx={{
            p: 3,
            borderRadius: "24px",
            bgcolor: "background.paper",
            border: "2px solid #DCDCDC",
          }}
          justifyContent="center"
          className="billing-form-wrap"
        >
          <CheckoutForm onFormValidChange={setFormCompleted} />
          <Button
            className="billing-form-btn"
            disabled={stripeInitiated || !formCompleted}
            variant="search"
            sx={{ width: 1 / 2 }}
            onClick={async () => {
              // clean data
              let itms = items.map((it) => {
                let item = { ...it };
                delete item.id;
                delete item.tmp;
                delete item.itemTotal;
                delete item.price;
                item.accommodation = item.meta.accommodation.id;
                item.prezziario = item.meta.prezziario.id;
                item.rate = item.meta.rate.id;
                item.treatment = item.meta?.treatment?.id || null;
                item.children = item.meta.children;
                delete item.meta;
                return item;
              });

              let extras = extra.map((ex) => {
                let e = {
                  id: ex.id,
                  quantity: ex.quantity,
                };
                return e;
              });
              let meta = { ...metadata };
              meta.hotel = metadata.hotel.id;
              console.log(itms);
              console.log(extras);
              console.log(meta);
              let billingInfo = JSON.parse(localStorage.getItem("billing"));

              if (isEmpty) {
                toast.error(translate("empty_cart"));
              } else {
                setStripeInitiated(true);
                try {
                  let { data } = await apiService.apiClient.post(
                    "/create-booking-session",
                    {
                      user: user.id,
                      billingInfo,
                      items: itms,
                      extras: extras,
                      meta: meta,
                    }
                  );
                  //   console.log("Session", data);
                  if (data && data.status == "ok") {
                    window.open(data.url, "_self");
                  }
                } catch (error) {
                  console.log(error);
                  setStripeInitiated(false);
                }
              }
            }}
          >
            {translate("_BOOK_UCF_")}
          </Button>
        </Grid>
      )}
    </>
  );
}

export default BillingInfo;
