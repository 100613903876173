import React, { useEffect } from "react";
import { Tooltip } from "react-tooltip";

function Persons({ black, children, white, tooltip }) {
  let persons = [];
  useEffect(() => {
    persons = [];
  }, []);

  for (let i = 0; i < black; i++) {
    persons.push(<i key={"black" + i} className="fa fa-user" />);
  }

  if (children > 0) {
    persons.push(
      <i
        key={"children"}
        style={{
          marginHorizontal: "0.2em",
        }}
      >
        {" "}
        +{" "}
      </i>
    );
    for (let i = 0; i < children; i++) {
      persons.push(
        <i
          key={"child" + i}
          className="fa fa-user"
          style={{
            fontSize: "0.7em",
          }}
        />
      );
    }
  }

  for (let i = 0; i < white; i++) {
    persons.push(<i key={"white" + i} className="fa fa-user-o" />);
  }

  return (
    <div
      tooltip={tooltip}
      className="flex flex-nowrap"
      data-tooltip-id="persons-tooltip"
      data-tooltip-place="left"
      data-tooltip-content={tooltip}
    >
      <Tooltip id="persons-tooltip" />
      {persons}
    </div>
  );
}

export default Persons;
