import React, { useState } from "react";

import SearchForm from "../components/SearchForm";
import LoginOrRegister from "../components/LoginOrRegister";

import LayoutContent from "../components/LayoutContent";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";
import styled from "styled-components";

const Container = styled.div`
  padding: 0px;
  margintop: 79px;
  text-align: center;
  @media (min-width: 768px) {
  }
`;

const Access = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);

  if (user) {
    navigate("/");
  }

  return (
    <LayoutContent
      SideComp={() => {
        return <SearchForm />;
      }}
      MainComp={() => {
        return <LoginOrRegister />;
      }}
    />
  );
};

export default Access;
