import { Grid } from "@mui/material";

import AppLayout from "./AppLayout";

function LayoutContent({ SideComp, MainComp, centered = false }) {
  return (
    <AppLayout>
      {!centered && (
        <Grid item xs={12} md={4} lg={3}>
          <SideComp />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={8}
        lg={9}
        p={{ sm: 0, md: 2 }}
        pt={3}
        py={{ sm: 3, md: 0 }}
        container={centered}
        justifyContent={centered ? "center" : "flex-start"}
      >
        <MainComp />
      </Grid>
      <Grid container item xs={12} justifyContent="center" mt={5}>
        <Grid
          container
          item
          xs={11}
          justifyContent="space-evenly"
          rowSpacing={3}
        >
          <Grid item xs={12} md={3} container justifyContent="center">
            <img
              src="/images/logo-unione-europea.png"
              alt="comune di terni"
              style={{
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="center">
            <img
              src="/images/logo-repubblica-italiana.png"
              alt="comune di terni"
              style={{
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="center">
            <img
              src="/images/logo-regione-umbria.png"
              alt="comune di terni"
              style={{
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="center">
            <img
              src="/images/logo-porfesr.png"
              alt="comune di terni"
              style={{
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  );
}

export default LayoutContent;
