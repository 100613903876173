import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalImage from "react-modal-image";
import MapWithMarkers from "./MapWithMarkers";
import ContentWrapper from "./ui/ContentWrapper";
import OfflineRequestForm from "./OfflineRequestForm";
import DoneIcon from "./DoneIcon";

import { useTranslation } from "react-i18next";
import { useCart } from "react-use-cart";
import { useSelector, useDispatch } from "react-redux";
import { setExtras } from "../redux/appSlice";
import { useNavigate } from "react-router-dom";
import Persons from "./Persons";
import RoomCard from "./RoomCard";
import { useSearchParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import { formatEuro, translateOne } from "../libs/utils";
import moment from "moment";

// Use Intl.DateTimeFormat to format the time
const timeFormatter = new Intl.DateTimeFormat("it-IT", {
  hour: "numeric",
  minute: "numeric",
  hour12: false, // Use 12-hour clock (true) or 24-hour clock (false)
});

const HotelInfo = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = useSelector((state) => state.app.lang);

  const { gallery, image } = data;
  const updatedGallery = [];

  if (image && image !== null && image !== undefined) {
    updatedGallery.push(image);
  }
  // hooks
  const {
    items,
    setItems,
    setCartMetadata,
    addItem,
    inCart,
    updateItem,
    removeItem,
  } = useCart();
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const couponUsed = app.couponCode != null && app.couponCode !== "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // state
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const [selected_accommodation, setSelectedAccommodation] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [formData, setFormData] = useState({});
  const [rows, setRows] = useState([]);
  const [gallery_images, setGalleryImages] = useState([]);
  //const [selectedOptions, setSelectedOptions] = useState({});
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const details = (event, accommodation) => {
    event.preventDefault();
    let translated = translateOne(accommodation);
    setSelectedAccommodation({
      ...accommodation,
      name: translated.name,
      description: translated.description,
    });
    setShowDetails(true);
  };

  const get_gallery_images = () =>
    data?.gallery?.map((image) => {
      return image?.formats?.large
        ? process.env.REACT_APP_BASE_URL + image?.formats?.large?.url
        : process.env.REACT_APP_BASE_URL + image?.formats?.medium?.url;
    });

  useEffect(() => {
    // reset everything
    setItems(items.filter((item) => !item.tmp));
    setGalleryImages(get_gallery_images());
    dispatch(setExtras([]));

    fillRows();
  }, []);

  const fillRows = () => {
    let rows = [];
    let base_data = {};

    const nrows = {};
    data?.accommodations.map((room) => {
      room?.rate_plans.map((rate, r_index) => {
        let maxChildren = couponUsed ? 0 : room.maxChildren;
        rate?.prezziari.map((prezzo, p_index) => {
          if (typeof nrows[`${room.id}_${rate.id}`] == "undefined") {
            nrows[`${room.id}_${rate.id}`] = 1;
          }
          // TODO: only calculate children if prezzo.children_price > 0
          nrows[`${room.id}_${rate.id}`] +=
            prezzo.children_price <= 0
              ? prezzo.treatments.length
              : prezzo.treatments.length * (maxChildren + 1);
        });
      });
    });

    const coupon = searchParams.get("couponCode") || "";
    data?.accommodations.map((room) => {
      room?.rate_plans.map((rate, r_index) => {
        let maxChildren = couponUsed ? 0 : room.maxChildren;
        rate?.prezziari.map((prezzo, p_index) => {
          if (p_index === 0) {
            base_data = {
              rowSpan: nrows[`${room.id}_${rate.id}`],
              accommodation_data: room,
              rate_data: rate,
              prezzo_data: prezzo,
              treatment_data: {
                id: 0,
                pricePerPerson: 0,
                pricePerChild: 0,
                name: "",
              },
              breakfast_included: prezzo.breakfast_included,
              tot_guests: prezzo.tot_guests,
              max_guests: room.max_guests,
              min_guests: room.min_guests,
              availability: couponUsed
                ? room.quantity
                : Math.min(
                    rate.availability,
                    rate.still_available_in_that_date
                  ),
              rate: translateOne(rate).name,
              rate_name: prezzo.breakfast_included
                ? t("breakfast_included")
                : t("room_only"),
              rate_description: rate.description,
              accommodation_id: room.id,
              rate_plan_id: rate.id,
              prezziario_id: prezzo.id,
              treatment_id: 0,
              room_name: translateOne(room).name,
              room_description: translateOne(room).description,
              price: (prezzo?.base_price || 0).toFixed(2),
              treatment_pricePerPerson: 0,
              treatment_pricePerChild: 0,
              children_price: 0,
              treatment_name: "",
              image: data.image,
              tot_children: 0,
            };

            rows.push({
              ...base_data,
            });
          }

          prezzo.treatments?.map((treatment) => {
            for (let i = 0; i <= maxChildren; i++) {
              if (
                i == 0 ||
                (i > 0 && (Number(prezzo?.children_price) || 0) > 0)
              )
                rows.push({
                  ...base_data,
                  rate: translateOne(rate).name,
                  accommodation_data: room,
                  rate_data: rate,
                  prezzo_data: prezzo,
                  treatment_data: treatment,
                  breakfast_included: prezzo.breakfast_included,
                  tot_guests: prezzo.tot_guests,
                  tot_children: i,
                  prezziario_id: prezzo.id,
                  rowSpan: -1,
                  rate_name: translateOne(treatment).name,
                  price: (prezzo?.base_price || 0).toFixed(2),
                  children_price:
                    i == 0 ? 0.0 : (prezzo?.children_price || 0).toFixed(2),
                  treatment_id: treatment.id,
                  treatment_pricePerPerson: treatment.pricePerPerson,
                  treatment_pricePerChild: treatment.pricePerChild,
                });
            }
          });
        });
      });
    });

    setRows(rows);
  };

  if (!data) return <div></div>;

  /*
  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  */

  const getServicesTypes = () => {
    let services = [];
    //console.log(data?.facility_services);
    data?.facility_services.map((service) => {
      if (
        !services.map((s) => s.id).includes(service.facility_service_type.id)
      ) {
        services.push(service.facility_service_type);
      }
    });
    //console.log(services);
    return services;
  };

  const getServicesByCategoryId = (categoryId) => {
    return data?.facility_services.filter(
      (service) => service.facility_service_type.id === categoryId
    );
  };

  const prenota = () => {
    const tmpCartItems = items
      .filter((item) => item.tmp)
      .map((item) => {
        return { ...item, tmp: false };
      });
    // Rimpiazza il carrello con il carrello temporaneo reso definitivo
    setItems(tmpCartItems);
    const meta = {
      hotel: data,
      from:
        searchParams.get("from") ||
        moment().startOf("day").format("YYYY-MM-DD"),
      to:
        searchParams.get("to") ||
        moment().startOf("day").add(1, "days").format("YYYY-MM-DD"),
      adults: searchParams.get("adults") || 1,
      children: searchParams.get("children") || 0,
      couponCode: searchParams.get("couponCode") || "",
      ages: searchParams.getAll("ages[]") || [],
    };
    setCartMetadata(meta);
    navigate("/extras");
  };

  const totalPrice = items
    .filter((item) => item.tmp)
    .reduce((acc, item) => acc + item.price * item.quantity, 0)
    .toFixed(2);

  const onChange = (event) => {
    let [
      accommodation_id,
      rate_plan_id,
      prezziario_id,
      treatment_id,
      children,
    ] = event.target.name.split("_");
    accommodation_id = parseInt(accommodation_id);
    rate_plan_id = parseInt(rate_plan_id);
    prezziario_id = parseInt(prezziario_id);
    treatment_id = parseInt(treatment_id);
    children = parseInt(children);
    const quantity = parseInt(event.target.value);

    let found_row = null;

    for (let row of rows) {
      if (
        row.accommodation_id === accommodation_id &&
        row.rate_plan_id === rate_plan_id &&
        row.prezziario_id === prezziario_id &&
        row.treatment_id === treatment_id &&
        row.tot_children === children
      ) {
        found_row = row;
        break;
      }
    }
    if (!found_row) return;
    const item = {
      tmp: true,
      id: event.target.name,
      quantity,
      price: (
        Number(found_row.price) +
        Number(found_row.treatment_pricePerPerson) *
          Number(found_row.tot_guests) +
        Number(found_row.children_price) +
        Number(found_row.treatment_pricePerChild) * children
      ).toFixed(2),
      meta: {
        accommodation: found_row.accommodation_data,
        rate: found_row.rate_data,
        prezziario: found_row.prezzo_data,
        treatment: found_row.treatment_data,
        children,
      },
    };

    /*
    const newSelectedOptions = {
      ...selectedOptions,
      [found_row.prezziario_id+"_"+found_row.treatment_id]: quantity,
    };

    setSelectedOptions(newSelectedOptions);
*/
    if (!inCart(item.id)) {
      addItem(item, item.quantity);
    } else {
      if (quantity === 0) removeItem(item.id);
      else updateItem(item.id, item);
    }
  };

  const table_contents = rows.map((row, i) => {
    return (
      <>
        <tr key={`table_contents${i}`} className="border-t-2 border-slate-200 ">
          {row.rowSpan > 0 && (
            <td rowSpan={row.rowSpan} className="w-1/3 p-2 align-top border">
              <p className="text-black hover:underline">
                <a
                  href="#"
                  onClick={(event) => details(event, row.accommodation_data)}
                >
                  {row.room_name}
                  <br></br>
                  <p>{row.rate}</p>
                  <p
                    style={{
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.54)",
                      fontStyle: "italic",
                      marginTop: 5,
                    }}
                  >
                    {row.rate_data.refundable
                      ? "Rimborsabile"
                      : "NON Rimborsabile"}
                  </p>
                </a>
              </p>

              <p className="mt-2 service__name" style={{ fontSize: 14 }}>
                {row.room_description}
              </p>
            </td>
          )}
          {((row.rowSpan > 0 && !row.breakfast_included) ||
            row.rowSpan == -1) && (
            <>
              <td className="p-2">
                <Persons
                  black={row.tot_guests}
                  children={row.tot_children}
                ></Persons>
              </td>
              <td className="p-2">
                {(
                  Number(row.price) +
                  Number(row.treatment_pricePerPerson) *
                    Number(row.tot_guests) +
                  (Number(row.children_price) +
                    Number(row.treatment_pricePerChild)) *
                    Number(row.tot_children)
                ).toFixed(2)}
              </td>
              <td className="p-2">
                <p>{row.rate_name}</p>
                <p>{row.treatment_name}</p>
              </td>
              <td className="p-2">
                {/*`${row.accommodation_id}_${row.rate_plan_id}_${row.prezziario_id}_${row.treatment_id}`*/}
                <select
                  name={`${row.accommodation_id}_${row.rate_plan_id}_${row.prezziario_id}_${row.treatment_id}_${row.tot_children}`}
                  onChange={onChange}
                >
                  <option value="0">0</option>
                  {Array.from(
                    { length: row.availability },
                    (_, i) => i + 1
                  ).map((i) => (
                    <option key={i} value={i}>
                      {i} -{" "}
                      {Number(
                        i *
                          (Number(row.price) +
                            Number(row.treatment_pricePerPerson) *
                              Number(row.tot_guests) +
                            Number(row.children_price) *
                              Number(row.tot_children))
                      ).toFixed(2)}{" "}
                      €
                    </option>
                  ))}
                </select>
              </td>
            </>
          )}
        </tr>
      </>
    );
  });

  const SingleData = rows.map((row, i) => {
    return (
      <>
        {row.rowSpan == -1 && (
          <div className="choose-room-wrap" key={`single_row${i}`}>
            <div className="room-name">
              <a
                href="#"
                onClick={(event) => details(event, row.accommodation_data)}
              >
                {row.room_name}
              </a>
            </div>
            <div className="tariff-plan-namw">
              <p>Piano tariffario</p>
            </div>
            <div
              className={`refundable-wrap ${
                row.rate_data.refundable ? "refundable" : "non-refundable"
              }`}
            >
              {row.rate_data.refundable ? "Rimborsabile" : "NON Rimborsabile"}
            </div>
            <p className="mt-2 service__name" style={{ fontSize: 14 }}>
              {row.room_description}
            </p>
            {((row.rowSpan > 0 && !row.breakfast_included) ||
              row.rowSpan == -1) && (
              <div className="flex items-center price-detail-wrap ">
                <div className="per-title">
                  <p>Per:</p>
                </div>
                <div className="person-wrap">
                  <Persons
                    black={row.tot_guests}
                    children={row.tot_children}
                  ></Persons>
                </div>
                <div className="treatment">
                  <p>{row.rate_name}</p>
                  <p>{row.treatment_name}</p>
                </div>
                <div className="price">
                  {(
                    Number(row.price) +
                    Number(row.treatment_pricePerPerson) *
                      Number(row.tot_guests) +
                    (Number(row.children_price) +
                      Number(row.treatment_pricePerChild)) *
                      Number(row.tot_children)
                  ).toFixed(2)}
                </div>
                <div className="select-room">
                  <select
                    name={`${row.accommodation_id}_${row.rate_plan_id}_${row.prezziario_id}_${row.treatment_id}_${row.tot_children}`}
                    onChange={onChange}
                  >
                    <option value="0">0</option>
                    {Array.from(
                      { length: row.availability },
                      (_, i) => i + 1
                    ).map((i) => (
                      <option key={i} value={i}>
                        {i} -{" "}
                        {Number(
                          i *
                            (Number(row.price) +
                              Number(row.treatment_pricePerPerson) *
                                Number(row.tot_guests) +
                              (Number(row.children_price) +
                                Number(row.treatment_pricePerChild)) *
                                Number(row.tot_children))
                        ).toFixed(2)}{" "}
                        €
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  });

  const groupedData = rows.reduce((acc, item) => {
    const key = `${item.accommodation_id || ""}_${item.room_name}_${item.rate}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const table_content_mobile = Object.keys(groupedData).map((id) => (
    <div key={id} className="choose-room-wrap">
      {groupedData[id].map((row, i) => {
        return (
          <>
            <div key={`table_contents_mobile${i}`}>
              {row.rowSpan > 0 && (
                <div>
                  <div className="room-name">
                    <a
                      href="#"
                      onClick={(event) =>
                        details(event, row.accommodation_data)
                      }
                    >
                      {row.room_name}
                    </a>
                  </div>
                  <div className="tariff-plan-namw">
                    <p>Piano tariffario</p>
                  </div>
                  <div
                    className={`refundable-wrap ${
                      row.rate_data.refundable ? "refundable" : "non-refundable"
                    }`}
                  >
                    {row.rate_data.refundable
                      ? "Rimborsabile"
                      : "NON Rimborsabile"}
                  </div>
                  <p className="mt-2 service__name" style={{ fontSize: 14 }}>
                    {row.room_description}
                  </p>
                </div>
              )}
              {((row.rowSpan > 0 && !row.breakfast_included) ||
                row.rowSpan == -1) && (
                <div className="flex items-center price-detail-wrap ">
                  <div className="per-title">
                    <p>Per:</p>
                  </div>
                  <div className="person-wrap">
                    <Persons
                      black={row.tot_guests}
                      children={row.tot_children}
                    ></Persons>
                  </div>
                  <div className="treatment">
                    <p>{row.rate_name}</p>
                    <p>{row.treatment_name}</p>
                  </div>
                  <div className="price">
                    {(
                      Number(row.price) +
                      (Number(row.treatment_pricePerPerson) *
                        Number(row.tot_guests) +
                        Number(row.children_price) * Number(row.tot_children))
                    ).toFixed(2)}
                  </div>
                  <div className="select-room">
                    <select
                      name={`${row.accommodation_id}_${row.rate_plan_id}_${row.prezziario_id}_${row.treatment_id}_${row.tot_children}`}
                      onChange={onChange}
                    >
                      <option value="0">0</option>
                      {Array.from(
                        { length: row.availability },
                        (_, i) => i + 1
                      ).map((i) => (
                        <option key={i} value={i}>
                          {i} -{" "}
                          {Number(
                            i *
                              (Number(row.price) +
                                Number(row.treatment_pricePerPerson) *
                                  Number(row.tot_guests) +
                                Number(row.children_price) *
                                  Number(row.tot_children) *
                                  i)
                          ).toFixed(2)}{" "}
                          €
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </>
        );
      })}
    </div>
  ));

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <Grid container gap={3}>
      <Grid item xs={12}>
        {/* <Carousel
          navigation={false}
          autoplay={true}
          className="slider-img-wrap mobile-slider-img-wrap"
        >
          {updatedGallery?.map((image, i) => (
            <img
              alt={`Image${i}`}
              key={i}
              src={process.env.REACT_APP_BASE_URL + image?.formats.small.url}
              className="object-cover w-full h-full"
            />
          ))}
        </Carousel> */}
        <div>
          <div
            onClick={() => openLightboxOnSlide(1)}
            className="cursor-pointer hotel-info-main-image"
            style={{
              backgroundImage: `url("${
                process.env.REACT_APP_BASE_URL + data.image?.formats.medium.url
              }")`,
            }}
          ></div>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={gallery_images}
            slide={lightboxController.slide}
          />
          <div className="cursor-pointer hotel-info__thumbnails">
            {data?.gallery?.map((image, i) => (
              <img
                src={
                  process.env.REACT_APP_BASE_URL + image?.formats.thumbnail.url
                }
                className="thumbnail"
                onClick={() => openLightboxOnSlide(i + 1)}
              />
            ))}
          </div>
        </div>
      </Grid>

      <Grid item xs={12} className="accordion-desktop">
        <ContentWrapper title={t("_DESCRIPTION_UCF_")}>
          {(data?.description || "").split("\n").map((line, i) => (
            <p className="hotel__description" key={i}>
              {line}
            </p>
          ))}
        </ContentWrapper>
      </Grid>
      <Grid className="accordion-mobile">
        <Accordion
          className="mb-0 accordion-item"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="accordion-title-wrap"
          >
            <Typography className="accordion-title" sx={{ flexShrink: 0 }}>
              {t("_DESCRIPTION_UCF_")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.description}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="mb-0 accordion-item"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            className="accordion-title-wrap"
          >
            <Typography className="accordion-title" sx={{ flexShrink: 0 }}>
              {t("Servizi")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} className="service-data">
              {getServicesTypes().map((service) => {
                const translatedService = translateOne(service);
                // console.log(service, translatedService);
                return (
                  <Grid
                    item
                    key={`service-type_${service.id}`}
                    xs={12}
                    md={4}
                    sx={{ mb: 2 }}
                  >
                    <Grid item container xs={12} gap={1}>
                      <p className="service__category">
                        {translatedService.name}
                      </p>
                      <Grid item container xs={12}>
                        {getServicesByCategoryId(service.id).map((f) => {
                          const translatedF = translateOne(f);
                          return (
                            <Grid item container xs={12} key={f.id}>
                              <Grid item xs={2}>
                                <DoneIcon />
                              </Grid>
                              <Grid item xs={10}>
                                <p className="service__name">
                                  {translatedF.name}
                                </p>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {data?.request_only ? (
        <OfflineRequestForm data={data} />
      ) : (
        <>
          <Grid item xs={12}>
            <ContentWrapper
              type="hotelInfo"
              title={t("_CHOOSE_THE_ROOM_AND_THE_RATE_YOU_PREFER_")}
            >
              <div className="pricing-table-desktop ">
                {table_contents.length == 0 ? (
                  <p>{t("_THERE_ARE_NO_ROOMS_IN_THE_SELECTED_PERIOD_")}</p>
                ) : (
                  <table
                    className="mt-4 text-sm"
                    border={1}
                    cellPadding={0}
                    cellSpacing={1}
                  >
                    <thead>
                      <tr className="text-black bg-blue-300 border">
                        <td className="p-2 border">{t("room_type")}</td>
                        <td className="p-2 border"></td>
                        <td className="p-2 border">{t("price")}</td>
                        <td className="p-2 border">{t("treatments")}</td>
                        <td className="p-2 border">{t("select_room")}</td>
                      </tr>
                    </thead>
                    <tbody>{table_contents}</tbody>
                  </table>
                )}
              </div>
              <div className="pricing-table-mobile">{table_content_mobile}</div>

              {showDetails && (
                <div className="pop-up-room-card">
                  <RoomCard
                    room={selected_accommodation}
                    onClose={() => setShowDetails(false)}
                    show={showDetails}
                  />
                </div>
              )}
            </ContentWrapper>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={12}
            className="total-bottom"
            //my={4}
          >
            <p className="carrello__totale">
              {t("_TOTAL_UCF_")}: {formatEuro(totalPrice)}
            </p>
            <Button
              disabled={totalPrice == 0}
              onClick={prenota}
              variant="search"
              sx={{ px: 6 }}
            >
              {t("_BOOK_NOW_UCF_")}
            </Button>
          </Grid>
        </>
      )}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        item
        xs={12}
      >
        <MapWithMarkers hotels={[data]} readOnly />
      </Grid>

      {/* SERVIZI */}
      <ContentWrapper className="desktop" title={t("Servizi")}>
        <Grid container spacing={2}>
          {getServicesTypes().map((service) => {
            let translatedService = translateOne(service);
            return (
              <Grid
                item
                key={`service-type_${service.id}`}
                xs={12}
                md={4}
                sx={{ mb: 2 }}
              >
                <Grid item container xs={12} gap={1}>
                  <p className="service__category">{translatedService.name}</p>
                  <Grid item container xs={12}>
                    {getServicesByCategoryId(service.id).map((f) => {
                      let translatedF = translateOne(f);
                      return (
                        <Grid item container xs={12} key={f.id}>
                          <Grid item xs={2}>
                            <DoneIcon />
                          </Grid>
                          <Grid item xs={10}>
                            <p className="service__name">{translatedF.name}</p>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </ContentWrapper>
      {/* Informazioni importanti */}
      <ContentWrapper
        type="hotelInfo"
        title={t("_INFORMAZIONI_IMPORTANTI_TITLE_")}
      >
        <div className="mobile-info-section">
          <Grid
            container
            spacing={2}
            className="hotel-check-in-out-info-desktop"
          >
            <div className="flex w-full pt-4 ps-4">
              <Grid item md={4} className="check-in-title">
                <p className="service__category">
                  {t("_ORARIO_CHECKIN_TITLE_")}
                  <span>:</span>
                </p>
              </Grid>
              <Grid item md={8} className="check-in-info">
                <p className="service__name">
                  {data?.checkinTime
                    ? timeFormatter.format(
                        new Date(`2000-01-01T${data.checkinTime}`)
                      )
                    : "non specificato"}
                </p>
              </Grid>
            </div>
            <div className="flex w-full pt-4 ps-4">
              <Grid item md={4} className="check-out-title">
                <p className="service__category">
                  {t("_ORARIO_CHECKOUT_TITLE_")}
                  <span>:</span>
                </p>
              </Grid>
              <Grid item md={8} className="check-out-info">
                <p className="service__name">
                  {data?.checkoutTime
                    ? timeFormatter.format(
                        new Date(`2000-01-01T${data.checkoutTime}`)
                      )
                    : "non specificato"}
                </p>
              </Grid>
            </div>
          </Grid>

          {/* <Grid item xs={12} my={4}>
            <Divider />
          </Grid> */}

          {/* <Grid container spacing={2}>
            <Grid item xs={12} md={4} className="checking-other-info">
              <p className="service__category">Cancellazione</p>
            </Grid>
            <Grid item xs={12} md={8} className="checking-other-info">
              <p className="service__name">
                Puoi cancellare gratuitamente fino a 2 giorni prima dell'arrivo.
                Se cancelli nei 2 giorni prima dell'arrivo ti sarà addebitato il
                costo della prima notte.
              </p>
            </Grid>
          </Grid> */}

          <Grid item xs={12} my={4}>
            <Divider />
          </Grid>
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={4} className="checking-other-info">
              <p className="service__category">{t("_ALTRE_INFO_")}</p>
            </Grid>
            <Grid item xs={12} md={8} className="checking-other-info">
              <p className="service__name">
                {data?.additionalInfo
                  ? data.additionalInfo
                  : "Nessuna informazione aggiuntiva"}
              </p>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} my={4}>
            <Divider />
          </Grid> */}

          {/* <Grid container spacing={2}>
            <Grid item xs={12} md={4} className="payment-method-wrap">
              <p className="service__category">
                {t("_METODI_DI_PAGAMENTO_ACCETTATI_")}
              </p>
            </Grid>
            <Grid item xs={12} md={8} container gap={2}>
              <img alt="" src="/images/cc_3.jpg" style={{ height: 40 }} />
              <img alt="" src="/images/cc_2.jpg" style={{ height: 40 }} />
              <img alt="" src="/images/cc_1.jpg" style={{ height: 40 }} />
            </Grid>
          </Grid> */}
        </div>
      </ContentWrapper>
    </Grid>
  );
};

export default HotelInfo;
