import React, { useEffect, useState } from "react";
import HotelInfo from "../components/HotelInfo";
import Stars from "../components/Stars";
import Carrello from "../components/Carrello-new";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { strutturaSearch } from "../services/api.service";
import LayoutContent from "../components/LayoutContent";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  setFrom,
  setTo,
  setAdults,
  setChildren,
  selectLang,
  setCouponCode,
  setChildrenAges,
} from "../redux/appSlice"; // Added imports
import { useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatEuro, translateLocaleObj } from "../libs/utils";
import moment from "moment";

const HotelPage = () => {
  const navigate = useNavigate();
  const lang = useSelector(selectLang);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [meta_data, setMeta_data] = useState(null);

  const getHotelData = async () => {
    const from =
      searchParams.get("from") || moment().startOf("day").format("YYYY-MM-DD");
    const to =
      searchParams.get("to") ||
      moment().startOf("day").add(1, "days").format("YYYY-MM-DD");
    const adults = searchParams.get("adults") || 1;
    const children = searchParams.get("children") || 0;
    const couponCode = searchParams.get("couponCode") || "";
    const ages = searchParams.getAll("ages[]") || [];

    setMeta_data({ from, to, adults, children, couponCode, ages });
    setLoading(true);

    // get hotel from api
    try {
      let hotelInfo = await strutturaSearch(
        id,
        from,
        to,
        adults,
        children,
        couponCode,
        ages
      );
      setData(translateLocaleObj(hotelInfo, lang));
      dispatch(setFrom(from));
      dispatch(setTo(to));
      dispatch(setAdults(parseInt(adults)));
      dispatch(setChildren(parseInt(children)));
      if (ages != null) {
        if (typeof ages.length !== "number") {
          dispatch(setChildrenAges([]));
        } else {
          dispatch(setChildrenAges(ages));
        }
      }
      if (couponCode != null && couponCode !== "") {
        dispatch(setCouponCode(couponCode));
      }
    } catch (error) {
      navigate("/");
    }
    setLoading(false);
  };

  useEffect(() => {
    //if (!id || loading) return;
    getHotelData();
  }, [id, searchParams, lang]);

  if (data == null) {
    return <div></div>;
  }
  return (
    <LayoutContent
      centered={data.request_only}
      SideComp={() => {
        return (
          <>
            {!data.request_only && (
              <Carrello
                meta={meta_data}
                real={false}
                data={data}
                hotelInfo={true}
                type="hotel"
              />
            )}
          </>
        );
      }}
      MainComp={() => {
        return (
          <Grid container gap={1}>
            <Grid item xs={12}>
              <Stars count={data.stars}></Stars>
            </Grid>
            <Grid item xs={12}>
              <p className="shared-title">{data.businessName}</p>
            </Grid>
            <Grid item xs={12} mb={2}>
              <p className="carrello__text">
                {data.address}
                {!data.request_only && data.accommodations.length > 0 && (
                  <span className="float-right hotel-card__price price-desktop">
                    {t("_FROM_UCF_")}&nbsp;
                    {Number(
                      data?.cheapest?.price < 99999 ? data?.cheapest?.price : 0
                    ).toFixed(2)}
                    €
                  </span>
                )}
                {!data.request_only && data.accommodations.length === 0 && (
                  <span className="float-right hotel-card__price price-desktop">
                    {t("_no_rooms")}
                  </span>
                )}
              </p>
              {!data.request_only && data.accommodations.length > 0 && (
                <span className="flex w-full hotel-card__price price-mobile">
                  {t("_FROM_UCF_")} {formatEuro(data?.cheapest?.price)}
                  {/* {Number(data?.cheapest?.price).toFixed(2)}€ */}
                </span>
              )}
              {!data.request_only && data.accommodations.length === 0 && (
                <span className="flex w-full hotel-card__price price-mobile">
                  {t("_no_rooms")}
                </span>
              )}
            </Grid>
            <Grid item xs={12}>
              <HotelInfo data={data} />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default HotelPage;
