import React from "react";

import { Grid, Card, Typography } from "@mui/material";

import Carrello from "../components/Carrello-new";
import LayoutContent from "../components/LayoutContent";

import { useCart } from "react-use-cart";

const NotFound = () => {
  const { metadata } = useCart();

  return (
    <LayoutContent
      SideComp={() => {
        return <Carrello meta={metadata} real={true} />;
      }}
      MainComp={() => {
        return (
          <Grid container justifyContent="center">
            <Card
              sx={{
                p: 5,
                backgroundColor: "#191F2B",
                borderRadius: 5,
              }}
            >
              <Grid container justifyContent="center">
                <img src="logo.png" alt="logo" />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} my={5}>
                  <Typography variant="h2" align="center" color="white">
                    404
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3" align="center" color="white">
                    Oops..
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Typography variant="h5" align="center" color="white">
                    La pagina che stai cercando non c'è
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center" color="white">
                    Prova a ricontrollare il link che ti ha portato qui
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      }}
    />
  );
};

export default NotFound;
