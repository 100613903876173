import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

import DatePickerMUI from "./ui/DatePickerMUI";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";

const dateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M9.85 9.72502V4.02502M20.15 9.72502V4.02502M11.825 15.1875L14.0875 17.45L18.5375 13M26.25 9.37501V20.625C26.25 22.0057 25.1307 23.125 23.75 23.125H6.25002C4.8693 23.125 3.75002 22.0057 3.75002 20.625V9.37501C3.75002 7.9943 4.8693 6.87501 6.25002 6.87501H23.75C25.1307 6.87501 26.25 7.9943 26.25 9.37501Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const osiptiIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.5125 14.8625C17.2739 14.8625 19.5125 12.6239 19.5125 9.86249C19.5125 7.10106 17.2739 4.86249 14.5125 4.86249C11.7511 4.86249 9.5125 7.10106 9.5125 9.86249C9.5125 12.6239 11.7511 14.8625 14.5125 14.8625Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2 25.1C20.8564 25.0859 21.4975 24.8999 22.0593 24.5603C22.6212 24.2208 23.0841 23.7398 23.4017 23.1652C23.7193 22.5907 23.8805 21.9429 23.8693 21.2865C23.858 20.6302 23.6746 19.9883 23.3375 19.425C22.4478 18.0206 21.2175 16.8639 19.7609 16.0624C18.3043 15.2609 16.6688 14.8407 15.0063 14.8407C13.3438 14.8407 11.7082 15.2609 10.2516 16.0624C8.79508 16.8639 7.56477 18.0206 6.67503 19.425C6.33789 19.9883 6.15451 20.6302 6.14326 21.2865C6.132 21.9429 6.29324 22.5907 6.61086 23.1652C6.92849 23.7398 7.39137 24.2208 7.95322 24.5603C8.51506 24.8999 9.15619 25.0859 9.8125 25.1H20.2Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const locationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M24.375 15C24.375 21.3871 17.2616 25.6573 15.4273 26.6522C15.1583 26.7981 14.8417 26.7981 14.5727 26.6522C12.7384 25.6573 5.625 21.3871 5.625 15C5.625 9.375 10.1675 5.625 15 5.625C20 5.625 24.375 9.375 24.375 15Z"
      stroke="#8B9AAD"
    />
    <circle cx="15" cy="15" r="4.5" stroke="#8B9AAD" />
  </svg>
);

const categoryIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M3.75 10.625C3.75 8.15939 3.75 6.92658 4.43097 6.09682C4.55563 5.94492 4.69492 5.80563 4.84682 5.68097C5.67658 5 6.90939 5 9.375 5H20.625C23.0906 5 24.3234 5 25.1532 5.68097C25.3051 5.80563 25.4444 5.94492 25.569 6.09682C26.25 6.92658 26.25 8.15939 26.25 10.625V11.5625C26.25 12.0803 25.8303 12.5 25.3125 12.5H25C23.6193 12.5 22.5 13.6193 22.5 15V15C22.5 16.3807 23.6193 17.5 25 17.5H25.3125C25.8303 17.5 26.25 17.9197 26.25 18.4375V19.375C26.25 21.8406 26.25 23.0734 25.569 23.9032C25.4444 24.0551 25.3051 24.1944 25.1532 24.319C24.3234 25 23.0906 25 20.625 25H9.375C6.90939 25 5.67658 25 4.84682 24.319C4.69492 24.1944 4.55563 24.0551 4.43097 23.9032C3.75 23.0734 3.75 21.8406 3.75 19.375V18.4375C3.75 17.9197 4.16973 17.5 4.6875 17.5H5C6.38071 17.5 7.5 16.3807 7.5 15V15C7.5 13.6193 6.38071 12.5 5 12.5H4.6875C4.16973 12.5 3.75 12.0803 3.75 11.5625V10.625Z"
      stroke="#8B9AAD"
    />
    <path
      d="M14.446 13.3607C14.6561 12.817 14.7612 12.5451 14.9319 12.5074C14.9768 12.4975 15.0232 12.4975 15.0681 12.5074C15.2388 12.5451 15.3439 12.817 15.554 13.3607C15.6736 13.6699 15.7333 13.8245 15.8451 13.9296C15.8765 13.9591 15.9105 13.9854 15.9468 14.0081C16.076 14.0889 16.2374 14.1039 16.56 14.1339C17.1062 14.1847 17.3793 14.21 17.4627 14.3716C17.48 14.4051 17.4918 14.4413 17.4975 14.4788C17.5251 14.6599 17.3244 14.8495 16.9228 15.2286L16.8113 15.3339C16.6236 15.5111 16.5297 15.5997 16.4754 15.7103C16.4428 15.7767 16.421 15.8481 16.4108 15.9218C16.3937 16.0447 16.4212 16.1733 16.4762 16.4304L16.4958 16.5223C16.5944 16.9834 16.6437 17.2139 16.5822 17.3273C16.5269 17.4291 16.4251 17.4942 16.3126 17.4998C16.1874 17.506 16.011 17.3568 15.6582 17.0585C15.4257 16.8619 15.3095 16.7636 15.1804 16.7252C15.0625 16.6901 14.9375 16.6901 14.8196 16.7252C14.6905 16.7636 14.5743 16.8619 14.3418 17.0585C13.989 17.3568 13.8126 17.506 13.6874 17.4998C13.5749 17.4942 13.4731 17.4291 13.4178 17.3273C13.3563 17.2139 13.4056 16.9834 13.5042 16.5223L13.5238 16.4304C13.5788 16.1733 13.6063 16.0447 13.5892 15.9218C13.579 15.8481 13.5572 15.7767 13.5246 15.7103C13.4703 15.5997 13.3764 15.5111 13.1887 15.3339L13.0772 15.2286C12.6756 14.8495 12.4749 14.6599 12.5025 14.4788C12.5082 14.4413 12.52 14.4051 12.5373 14.3716C12.6207 14.21 12.8938 14.1847 13.44 14.1339C13.7626 14.1039 13.924 14.0889 14.0532 14.0081C14.0895 13.9854 14.1235 13.9591 14.1549 13.9296C14.2667 13.8245 14.3264 13.6699 14.446 13.3607Z"
      fill="#8B9AAD"
      stroke="#8B9AAD"
    />
  </svg>
);

function InputSection({
  label,
  icon,
  type,
  name,
  value,
  minValue,
  options,
  onChange = null,
  onClick = null,
}) {
  const inputRef = useRef(null);

  return (
    <TextField
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ margin: 0 }}>
            <IconButton
              onFocus={() => {
                if (type == "date") {
                  inputRef.current.type = "date";
                  inputRef.current.min = minValue;
                }
              }}
              onClick={() => {
                type == "date" && inputRef.current.showPicker();
              }}
            >
              {icon}
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={label}
      value={value}
      name={name}
      type="text"
      select={type == "select"}
      variant="outlined"
      onFocus={() => {
        if (type == "date") {
          inputRef.current.type = "date";
          inputRef.current.min = minValue;
        }
      }}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      onClick={(val) => {
        if (onClick) onClick(val);
      }}
      InputLabelProps={{ shrink: true }}
      fullWidth
      sx={{
        color: "black",
        backgroundColor: "white",

        borderRadius: "5px",
        border: "1px solid #E6E6E6",

        "& .MuiInputBase-input": {
          height: 30,
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          //color: "black",
          padding: "12px 10px 12px 0px", // Custom padding
          color: "#8B9AAD",
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "130%" /* 20.8px */,
        },

        "& .MuiInputBase-root": {
          pl: 1,
        },
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function Counter({ min = 0, max = 10, value, setValue }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #eee",
        p: 1,
        borderRadius: "8px",
      }}
    >
      <IconButton
        onClick={() => {
          setValue(Math.max(min, value - 1));
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M18 12L6 12" stroke="#333C56" strokeLinecap="round" />
        </svg>
      </IconButton>
      <Box sx={{ px: 2 }}>{value}</Box>
      <IconButton
        onClick={() => {
          setValue(Math.min(max, value + 1));
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M12 6L12 18" stroke="#333C56" strokeLinecap="round" />
          <path d="M18 12L6 12" stroke="#333C56" strokeLinecap="round" />
        </svg>
      </IconButton>
    </Box>
  );
}

function Widget() {
  const { i18n } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);

  //GESTIONE DATE
  const [from, setFrom] = useState("Check-in");
  const [to, setTo] = useState("Check-out");
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [minFrom, setMinFrom] = useState(today.toISOString().split("T")[0]);
  const [minTo, setMinTo] = useState(tomorrow.toISOString().split("T")[0]);

  const handleDateChange = (e) => {
    if (e.target.name == "from") {
      setFrom(e.target.value);

      let nextDay = new Date(e.target.value);
      nextDay.setDate(nextDay.getDate() + 1);
      setMinTo(nextDay.toISOString().split("T")[0]);
      if (new Date(to) < nextDay) {
        setTo(nextDay.toISOString().split("T")[0]);
      }
    } else {
      setTo(e.target.value);
    }
  };

  //GESTIONE OSPITI
  const [couponCode, setCouponCode] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOspitiClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOspitiClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "ospiti-popover" : undefined;

  //GESTIONE ESPERIENZE
  const [location, setLocation] = useState("terni");
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const language = searchParams.get("lang") || "it";
    i18n.changeLanguage(language);

    async function fetchData() {
      const res = await fetch(
        "http://api2.apphotels.com:1369/api/categorie-prodotto?filters[is_sat]=true&locale=it"
      );
      const data = await res.json();
      return data;
    }

    fetchData()
      .then((data) => {
        //console.log(data);
        let category_options = [{ value: 0, label: "Seleziona Tutte" }];
        data?.data.forEach((item) => {
          category_options.push({
            value: item.id,
            label: item.attributes.nome,
          });
        });
        setCategories(
          category_options.sort(
            (a, b) => a.appearance_order - b.appearance_order
          )
        );
      })
      .catch((err) => console.log(err));
  }, []);

  //GESTIONE RICERCA
  const handleSearch = () => {
    if (selectedTab == 0) {
      window.location.href = `https://booking.viviterni.it?start=${from}&end=${to}&adults=${adults}&children=${children}&couponCode=${couponCode}&lang=${i18n.language}`;
    }
    if (selectedTab == 1) {
      let cat = categories.find((item) => item.value == category);
      if (cat.value == 0) cat.label = "null";
      window.open(
        `https://esperienze.viviterni.it/widget?location=${location}&category=${cat.label}&grid=3&gridSize=12`,
        "_blank"
      );
    }
  };

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      p={3}
      className="widget-wrapper"
    >
      {/* <Grid container justifyContent="center" className="menu" gap={4}>
        <p
          onClick={() => {
            setSelectedTab(0);
          }}
          style={{ borderBottomWidth: selectedTab == 0 ? "2px" : "0px" }}
        >
          Soggiorni
        </p>
        <p
          onClick={() => {
            setSelectedTab(1);
          }}
          style={{ borderBottomWidth: selectedTab == 1 ? "2px" : "0px" }}
        >
          Esperienze
        </p>
      </Grid> */}

      {selectedTab == 0 ? (
        // INPUT SOGGIORNI
        <Grid container spacing={3} mb={5}>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "white" }}>{t("coupon_code")}</InputLabel>
            <InputSection
              label="Coupon"
              icon={<LocalActivityIcon />}
              onChange={(e) => {
                setCouponCode(e.target.value);
              }}
              value={couponCode}
              type="text"
              name="couponCode"
            />
          </Grid>
          <Grid item xs={6}>
            {/* <InputSection
              label="Check-in"
              icon={dateIcon}
              onChange={handleDateChange}
              value={from}
              minValue={minFrom}
              type="date"
              name="from"
            /> */}
            <InputLabel sx={{ color: "white" }}>{t("arrival")}</InputLabel>
            <DatePickerMUI
              fullWidth
              onChange={(date) => {
                setFrom(date);
                let nextDay = moment(date).add(1, "days").toDate();
                setMinTo(nextDay);
                if (moment(to) < moment(nextDay) && moment(to) > moment(date)) {
                  setTo(nextDay);
                }
              }}
              value={from}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel sx={{ color: "white" }}>{t("departure")}</InputLabel>
            <DatePickerMUI
              fullWidth
              onChange={(date) => setTo(date)}
              value={to}
              disableDay={(date) => date < moment(minTo)}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <InputLabel sx={{ color: "white" }}>{t("guests")}</InputLabel>
            <InputSection
              label="Ospiti"
              icon={osiptiIcon}
              onClick={handleOspitiClick}
              value={`${t("guests")}: ${adults + children}`}
              type="text"
              name="ospiti"
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleOspitiClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Grid container spacing={3} py={3} px={2} width={376}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mx={2}
                >
                  <p>{t("adults")}</p>
                  <Counter min={1} value={adults} setValue={setAdults} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mx={2}
                >
                  <p>{t("children")}</p>
                  <Counter min={0} value={children} setValue={setChildren} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleOspitiClose}
                  >
                    {t("done")}
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
      ) : (
        // INPUT ESPERIENZE
        <Grid container spacing={5} mb={5}>
          <Grid item xs={12}>
            {/* <InputSection
              label="Dove"
              icon={locationIcon}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              value={location}
              options={[
                { value: 0, label: "Dove" },
                { value: "terni", label: "Terni" },
              ]}
              type="select"
              name="location"
            /> */}
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "white" }}>Cosa Stai Cercando?</InputLabel>
            <InputSection
              icon={categoryIcon}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              value={category}
              options={categories}
              type="select"
              name="category"
            />
          </Grid>
        </Grid>
      )}
      <Button
        variant="search"
        fullWidth
        onClick={handleSearch}
        disabled={
          selectedTab == 0
            ? from == "Check-in" || to == "Check-out"
            : location == "Dove" || category == 0
        }
      >
        {t("search")}
      </Button>
    </Grid>
  );
}

export default Widget;
