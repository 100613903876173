import LayoutContent from "../components/LayoutContent";
import ListLeft from "../components/ListLeft";
import ChangePasswordForm from "../components/ChangePasswordForm";

const ChangePassword = () => {
  return (
    <LayoutContent
      SideComp={() => {
        return <ListLeft />;
      }}
      MainComp={() => {
        return <ChangePasswordForm />;
      }}
    />
  );
};

export default ChangePassword;
