import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import apiService from "../services/api.service";

const UserSettings = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      navigate("/access");
    } else {
      // set form values
      formik.setFieldValue("firstName", user.firstName);
      formik.setFieldValue("lastName", user.lastName);
      formik.setFieldValue("email", user.email);
      formik.setFieldValue("codFisc", user.codFisc);
      formik.setFieldValue("promo", user.messages_subscribed);
    }
  }, [user, navigate]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Campo obbligatorio")),
    lastName: Yup.string().required(t("Campo obbligatorio")),
    email: Yup.string()
      .email(t("Formato email non valido"))
      .required(t("Campo obbligatorio")),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      codFisc: "",
      promo: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      console.log(values);
      try {
        const res = await apiService.apiClient.put(
          `${process.env.REACT_APP_API_URL}/users/${user.id}`,
          { data: values }
        );
        toast.success("Modifica avvenuta con successo");
      } catch {
        toast.error("Errore durante la modifica delle informazioni utente");
      }
    },
  });

  const classes = {
    root: {
      flexGrow: 1,
    },
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        gap={3}
        sx={{
          p: 3,
          borderRadius: "24px",
          bgcolor: "background.paper",
          border: "2px solid #DCDCDC",
        }}
      >
        <Grid item xs={12}>
          <p className="results__title">{t("Impostazioni personali")}</p>
        </Grid>

        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            label={t("Nome")}
            {...formik.getFieldProps("firstName")}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && t(formik.errors.firstName)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            label={t("Cognome")}
            {...formik.getFieldProps("lastName")}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && t(formik.errors.lastName)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            label="Email"
            {...formik.getFieldProps("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && t(formik.errors.email)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            label={t("Codice Fiscale")}
            {...formik.getFieldProps("codFisc")}
            error={formik.touched.codFisc && Boolean(formik.errors.codFisc)}
            helperText={formik.touched.codFisc && t(formik.errors.codFisc)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.promo}
                  onChange={formik.handleChange}
                  name="promo"
                />
              }
              label={t("Voglio ricevere offerte speciali e promozioni")}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Button
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            variant="search"
            type="submit"
          >
            {t("Salva")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserSettings;
