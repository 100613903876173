import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    lang: localStorage.getItem("language") || "it",
    spinner: 0,
    from: "",
    to: "",
    couponCode: "",
    adults: 1,
    children: 0,
    childrenAges: [],
    extras: [],
    currentHotel: null,
    cart: {
      struttura_id: 0,
      checkinDate: moment().startOf("day").format("DD/MM/YYYY"),
      checkoutDate: moment().startOf("day").add(1, "days").format("DD/MM/YYYY"),
      couponCode: "",
      adults: 1,
      children: 0,

      customer: {
        firstName: "",
        lastName: "",
        sex: "",
        language: "",
        country: "",
        email: "",
        phone: "",
      },
      camere: [], // {accommodation_id, rate_plan_id, prezziario_id, occupanti}
      extras: [], // {extra_id: 0, quantity: 0}
    },
  },
  reducers: {
    setLang: (state, action) => {
      localStorage.setItem("language", action.payload);

      return {
        ...state,
        lang: action.payload,
      };
    },
    setCurrentHotel: (state, action) => ({
      ...state,
      currentHotel: action.payload,
    }),
    setExtras: (state, action) => ({ ...state, extras: action.payload }),
    addSpinner: (state, action) => ({ ...state, spinner: 1 }),
    removeSpinner: (state, action) => ({
      ...state,
      spinner: 0,
    }),
    setFrom: (state, action) => ({ ...state, from: action.payload }),
    setTo: (state, action) => ({ ...state, to: action.payload }),
    setCouponCode: (state, action) => ({
      ...state,
      couponCode: action.payload,
    }),
    setAdults: (state, action) => ({ ...state, adults: action.payload }),
    setChildren: (state, action) => {
      const newState = { ...state, childrenAges: [...state.childrenAges] };
      // Riduzione dell'array
      if (newState.childrenAges.length > action.payload) {
        newState.childrenAges = newState.childrenAges.slice(0, action.payload);
      }
      // Aggiunta di elementi all'array
      if (newState.childrenAges.length < action.payload) {
        for (let i = newState.childrenAges.length; i < action.payload; i++) {
          newState.childrenAges.push(0);
        }
      }

      return { ...newState, children: action.payload };
    },
    setChildrenAges: (state, action) => {
      return { ...state, childrenAges: action.payload };
    },
  },
});

export const {
  setLang,
  addSpinner,
  removeSpinner,
  setCouponCode,
  setFrom,
  setTo,
  setAdults,
  setChildren,
  setChildrenAges,
  setExtras,
  setCurrentHotel,
} = appSlice.actions;
export const selectSpinner = (state) => state.app.spinner;
export const selectFrom = (state) => state.app.from;
export const selectTo = (state) => state.app.to;
export const selectCouponCode = (state) => state.app.couponCode;
export const selectAdults = (state) => state.app.adults;
export const selectChildren = (state) => state.app.children;
export const selectChildrenAges = (state) => state.app.childrenAges;
export const selectExtras = (state) => state.app.extras;
export const selectCurrentHotel = (state) => state.app.currentHotel;
export const selectLang = (state) => state.app.lang;

export default appSlice.reducer;
