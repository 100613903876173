// src/components/LandingPage.js
import React, { useEffect, useState } from "react";
import SearchForm from "../components/SearchForm";
import Results from "../components/Results";
import { getAvailableHotels, search } from "../services/api.service";
import { normalizeData } from "../libs/utils";
import FiltersForm from "../components/FiltersForm";
import { useDispatch, useStore } from "react-redux";
import { setResults } from "../redux/resultsSlice";
import { Grid } from "@mui/material";
import {
  setFrom,
  setTo,
  setAdults,
  setChildren,
  setLang,
} from "../redux/appSlice"; // Added imports
import LayoutContent from "../components/LayoutContent";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const app = useStore().getState().app;
  const [searchParams, setSearchParams] = useSearchParams();

  const [showSearch, setShowSearch] = useState(true);
  const [showEditSearch, setShowEditSearch] = useState(false);

  const handleSearchToEditClick = () => {
    if (window.innerWidth < 899) {
      setShowSearch(false);
      setShowEditSearch(true);
    }
  };

  const handleEditToSearchClick = () => {
    setShowSearch(true);
    setShowEditSearch(false);
  };

  useEffect(() => {
    const init = (from, to, adults, children, couponCode, ages) => {
      from = from || new Date().toISOString().split("T")[0];
      to =
        to ||
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];
      adults = typeof adults != "undefined" && adults !== 0 ? adults : 2;
      children = typeof children != "undefined" ? children : 0;

      // Test se from é nel passato
      if (new Date(from) < new Date().setHours(0, 0, 0, 0)) {
        from = new Date().toISOString().split("T")[0];
      }

      // test se to è nel passato
      if (new Date(to) < new Date().setHours(0, 0, 0, 0)) {
        to = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];
      }

      // test se to è minore o uguale a from
      if (new Date(to) <= new Date(from)) {
        to = new Date(new Date(from).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];
      }

      getAvailableHotels(from, to, adults, children, couponCode, ages).then(
        (data) => {
          dispatch(setFrom(from));
          dispatch(setTo(to));
          dispatch(setAdults(adults));
          dispatch(setChildren(children));
          try {
            const norm = normalizeData(data);
            dispatch(setResults(norm));
          } catch (error) {
            console.error(error);
          }
        }
      );
    };
    let qfrom = searchParams.get("start");
    let qto = searchParams.get("end");
    let qadults = searchParams.get("adults");
    let qchildren = searchParams.get("children");
    let qages = searchParams.getAll("ages[]") || [];
    let qcoupon = searchParams.get("couponCode") || "";

    // se i parametri non sono indefiniti
    if (
      qfrom &&
      qto &&
      typeof qadults != "undefined" &&
      typeof qchildren != "undefined"
    ) {
      init(qfrom, qto, parseInt(qadults), parseInt(qchildren), qcoupon, qages);
    } else {
      init(
        app.from,
        app.to,
        app.adults,
        app.children,
        app.couponCode,
        app.childrenAges
      );
    }
  }, [app, searchParams]);

  useEffect(() => {
    let qLang = searchParams.get("lang") || "it";
    if (!qLang) {
      return;
    }

    console.log(qLang);
    // set i18n language
    i18n.changeLanguage(qLang);
    // set redux language
    dispatch(setLang(qLang));
  }, [searchParams]);

  return (
    <LayoutContent
      SideComp={() => {
        return (
          <>
            {showSearch && <SearchForm onSearch={handleSearchToEditClick} />}
            <FiltersForm />
          </>
        );
      }}
      MainComp={() => {
        return (
          <Results
            onSearch={handleEditToSearchClick}
            showEditSearch={showEditSearch}
          />
        );
      }}
    />
  );
};

export default LandingPage;
