import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import resultsReducer from './resultsSlice';
import appReducer from './appSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web


const rootReducer = combineReducers({
  user: userReducer,
  results: resultsReducer,
  app: appReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['results']
  // Optionally, you can add a whitelist or blacklist for specific reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

