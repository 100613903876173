import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Button, TextField, Container, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import apiService from "../services/api.service";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
});

const BasicPopup = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required(t("Campo richiesto")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.locale = i18n.language;
      values.origin = "fe-booking";
      try {
        const data = await apiService.apiClient.post(
          `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
          values
        );
        toast.success(t("reset_password_email_sent"));
        //dispatch(setUser(data.user));
        //navigate to home
        setOpenPopup(false);
      } catch (error) {
        console.error(error);
        toast.error(t("error_sending_email"));
      }
    },
  });

  const { title, children, openPopup, setOpenPopup } = props;
  return (
    <Dialog open={openPopup} maxWidth="md">
      <DialogContent>
        <IconButton
          onClick={() => {
            setOpenPopup();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <HighlightOffIcon />
        </IconButton>
        <Container component="main">
          <form onSubmit={formik.handleSubmit}>
            <div className="column">
              <h5 style={{ margin: "1rem" }}>{t("Recupero password")}</h5>
              <div style={{ margin: "1rem" }}>
                {t("Inserisci la tua e-mail per reimpostare la password")}
              </div>
              <div className="row">
                <TextField
                  sx={{ margin: "1rem" }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  {...formik.getFieldProps("email")}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="error"
                  style={{ margin: "1rem", alignItem: "center" }}
                >
                  {t("Continua")}
                </Button>
              </div>
            </div>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default BasicPopup;
