import { Grid } from "@mui/material";

import NavbarResponsive from "./NavbarResponsive";
import Footer from "./Footer";

import "../App.css";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function App({ children }) {
  const { t: translate } = useTranslation();
  const router = useLocation();
  const { pathname } = router;

  useEffect(() => {
    if (pathname === "/extras" || pathname.startsWith("/hotel/")) {
      document.getElementById("root").classList.add("bottom-sticky");
    } else {
      document.getElementById("root").classList.remove("bottom-sticky");
    }
  }, [pathname]);
  return (
    <div className="App">
      <div className="header">
        <div className="darkened-bg"></div>
        <NavbarResponsive />
        <div className="headline">{translate("book_your_room")}</div>
      </div>
      <Grid container maxWidth={1440} m={"0 auto"}>
        <Grid
          className="container-main"
          container
          item
          xs={12} //xl={11}
          justifyContent={"center"}
          px={{ xs: 2, md: 4 }}
          pt={{ md: 12, xs: 6 }}
          pb={8}
          spacing={{ md: 4, xs: 0 }}
        >
          {children}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
