import { useState, useEffect } from "react";

import ListLeft from "../components/ListLeft";
import LayoutContent from "../components/LayoutContent";
import MyBookings from "../components/MyBookings";

import ApiService from "../services/api.service";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";
import { normalizeData, translateLocaleObj } from "../libs/utils";

const MyOrdersComponent = () => {
  const { t: translate } = useTranslation();
  const language = window.localStorage.getItem("language") || "it";

  const axios = ApiService.apiClient;
  const user = useSelector(selectUser);

  const [bookings, setBookings] = useState([]);

  const loadBookings = async () => {
    const qs = require("qs");
    const query = qs.stringify(
      {
        filters: {
          user: user.id,
        },
        populate: {
          user: true,
          booking_payments: true,
          coupon: true,
          facility: {
            populate: {
              image: true,
              localizations: { populate: true },
            },
          },
          extras: {
            populate: {
              image: true,
              price_type: true,
              localizations: { populate: true },
            },
          },
          booking_items: {
            populate: {
              accommodation: {
                populate: {
                  image: true,

                  accommodation_type: true,
                  accommodation_services: true,
                  localizations: { populate: true },
                },
              },
              rate_plan: {
                populate: {
                  prezziari: true,
                  localizations: { populate: true },
                },
              },
              prezziario: true,
              treatment: true,
            },
          },
        },
        pagination: {
          page: 1,
          pageSize: 999,
        },
        sort: "createdAt:desc",
        locale: language,
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    let { data } = await axios.get("/prenotazioni?" + query);
    let norm = normalizeData(data);
    norm.forEach((element) => {
      element = translateLocaleObj(element, language);
      // console.log(element);
    });
    setBookings(norm);
  };

  useEffect(() => {
    loadBookings();
  }, []);

  return (
    <LayoutContent
      SideComp={() => {
        return <ListLeft />;
      }}
      MainComp={() => {
        return <MyBookings bookings={bookings} reloadBookings={loadBookings} />;
      }}
    />
  );
};

export default MyOrdersComponent;
