import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import Stars from "./Stars";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectResults, setFilters } from "../redux/resultsSlice";
import { translateOne } from "../libs/utils";
import { selectLang } from "../redux/appSlice";

function FiltersForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);

  const [formValues, setFormValues] = useState({
    stars: [],
    localities: [],
    services: [],
  });

  const results = useSelector(selectResults);
  const [curr_stars, setCurrStars] = useState([]);
  const [curr_localities, setCurrLocalities] = useState([]);
  const [curr_facilities, setCurrFacilities] = useState([]);
  //dispatch(setFilters({ stars:[], localities: [], services: []}));

  useEffect(() => {
    if (results) {
      let starsSorted = results
        .map((v) => v.stars)
        .toSorted((a, b) => -1 * (a - b));
      let stars = starsSorted
        .map((v) => v + "")
        .filter((value) => value > 0 && value != null);
      // Stars unique
      stars = [...new Set(stars)];
      setCurrStars(stars);

      let localitiesSorted = results
        .map((v) => v.city)
        .toSorted((a, b) => a - b);
      let cities = localitiesSorted.filter((value) => value !== "");
      // Cities unique
      cities = [...new Set(cities)];
      setCurrLocalities(cities);
      //localities = results.map(item => item.locality);
      //services = results.map(item => item.services).flat();

      let facilitiesSorted = results
        .map((v) => v.facility_services)
        .flat()
        .map((v) => translateOne(v, lang)?.name)
        .toSorted((a, b) => a - b);

      // Facilities unique
      facilitiesSorted = [...new Set(facilitiesSorted)];
      setCurrFacilities(facilitiesSorted);
    }
  }, [results, lang]);

  useEffect(() => {
    //console.log("formValues", formValues);
    setFormValues({
      stars: [],
      localities: [],
      services: [],
    });
  }, [lang]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "localities" || name === "services" || name === "stars") {
      setFormValues((prevValues) => {
        let newState = {
          ...prevValues,
          [name]: checked
            ? [...prevValues[name], value]
            : prevValues[name].filter((item) => item !== value),
        };

        dispatch(
          setFilters({
            stars: newState.stars,
            localities: newState.localities,
            services: newState.services,
          })
        );
        return newState;
      });
    }
  };

  const handleSubmit = () => {
    console.log(formValues);
  };

  return (
    <div className="filters filter-main">
      <form>
        <FormLabel component="legend" className="title">
          {t("_FILTER_BY_")}:
        </FormLabel>

        <FormLabel component="legend" className="separator">
          {t("_STARS_")}
        </FormLabel>
        <div style={{ marginTop: "16px" }}>
          <FormGroup>
            {curr_stars.map((stars) => (
              <FormControlLabel
                key={stars}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="stars"
                    value={stars}
                    checked={formValues.stars.includes(stars)}
                  />
                }
                label={
                  <Stars count={stars} nostars="true" noempty="true"></Stars>
                }
              />
            ))}
          </FormGroup>
        </div>
        {/* }
            <FormLabel component="legend" className="sectionSeparator"></FormLabel>
            
            <FormLabel component="legend" className="separator">{t('Località')}</FormLabel>
            <div style={{ marginTop: '16px' }}>
                <FormGroup>
                    {curr_localities.map(locality => (
                        <FormControlLabel
                            key={locality}
                            control={
                                <Checkbox
                                style={{ color: 'black' }}
                                    onChange={handleChange}
                                    name="localities"
                                    value={locality}
                                    checked={formValues.localities.includes(locality)}
                                />
                            }
                            label={locality}
                        />
                    ))}
                </FormGroup>
            </div>
            */}
        <FormLabel component="legend" className="sectionSeparator"></FormLabel>
        <FormLabel component="legend" className="separator">
          {t("_SERVICES_")}
        </FormLabel>
        <div style={{ marginTop: "16px" }}>
          <FormGroup>
            {curr_facilities.map((service) => (
              <FormControlLabel
                key={service}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="services"
                    value={service}
                    checked={formValues.services.includes(service)}
                  />
                }
                label={service}
              />
            ))}
          </FormGroup>
        </div>
      </form>
    </div>
  );
}

export default FiltersForm;
