import { Grid } from "@mui/material";
import React from "react";

function ContentWrapper({ type=null, title, children, wrapperAdditionalStyle = {},className=null }) {
  return (
    
    <Grid
      container
      gap={3}
      className={className?className:""}
      sx={{
        p:{
          xs:type === "hotelInfo"?0:3,
          md:3
        },
        borderRadius: "24px",
        bgcolor: "background.paper",
        border:{  
          xs: type === "hotelInfo"?"":"2px solid #DCDCDC",
          md: "2px solid #DCDCDC"
        },
        ...wrapperAdditionalStyle,
      }}
    >
      {title && (
        <Grid item xs={12} className="desktop-one">
          <p className="content-wrapper-title">{title}</p>
        </Grid>
      )}
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default ContentWrapper;
