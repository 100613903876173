import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { selectLang, setLang } from "../redux/appSlice";
import { setFilters } from "../redux/resultsSlice";

function LanguageSwitch() {
  const { i18n } = useTranslation();
  const lang = useSelector(selectLang);
  const { dispatch } = useStore();

  useEffect(() => {
    dispatch(setFilters({ stars: [], localities: [], services: [] }));
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  // Handle language change
  const changeLanguage = (event) => {
    dispatch(setLang(event.target.value));
  };

  return (
    <select
      value={lang}
      onChange={changeLanguage}
      className="language-switcher"
    >
      <option value="en" className="language-option">
        🇬🇧 En
      </option>
      <option value="it" className="language-option">
        🇮🇹 It
      </option>
    </select>
  );
}

export default LanguageSwitch;
