import { useEffect } from "react";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

import { createTheme, Grid, ThemeProvider } from "@mui/material";
import "font-awesome/css/font-awesome.min.css";

import { CartProvider } from "react-use-cart";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { removeSpinner } from "./redux/appSlice";

import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import ThingsToDo from "./pages/ThingsTodo";
import About from "./pages/About";
import Access from "./pages/access";
import Logout from "./pages/Logout";
import Session from "./components/Session";
import HotelPage from "./pages/Hotel";
import Spinner from "./components/Spinner";
import Profile from "./pages/Profile";
import MyOrdersComponent from "./pages/MyOrders";
import ChangePassword from "./pages/ChangePassword";
import ResetPassword from "./pages/ResetPassword";
import Extra from "./pages/Extra";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Widget from "./components/Widget";

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "search" },
          style: {
            color: "white",
            fontSize: "20px",
            fontWeight: 600,
            textTransform: "none",
            padding: "10px 30px",
            backgroundColor: "#D00",
            "&:hover": {
              backgroundColor: "#E00",
            },
          },
        },
        {
          props: { variant: "bookNow" },
          style: {
            color: "white",
            fontSize: "18px",
            fontWeight: "600;",
            textTransform: "none",
            padding: "10px 30px",
            backgroundColor: "#D00",
            "&:hover": {
              backgroundColor: "#E00",
            },
          },
        },
        {
          props: { variant: "requestNow" },
          style: {
            color: "white",
            fontSize: "18px",
            fontWeight: "600;",
            textTransform: "none",
            padding: "10px 30px",
            backgroundColor: "#464D77",
            "&:hover": {
              backgroundColor: "#333C56",
            },
          },
        },
        {
          props: { variant: "carrello" },
          style: {
            color: "#333C56",
            fontSize: "16px",
            fontWeight: "600;",
            textTransform: "none",
            padding: "10px 30px",
            borderRadius: 5,
            border: "2px solid #E5E8F3",
          },
        },
      ],
    },
  },
  typography: {
    palette: {
      transparent: "transparent",
    },
    fontFamily: ["Montserrat"].join(","),
  },
});

let persistor = persistStore(store);

function App() {
  useEffect(() => {
    store.dispatch(removeSpinner());
  }, []);

  return (
    <CartProvider>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Spinner />
              <Session></Session>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/hotel/:id" element={<HotelPage />} />
                  <Route path="/access" element={<Access />} />
                  <Route path="/things-to-do" element={<ThingsToDo />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/my-orders" element={<MyOrdersComponent />} />
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/extras" element={<Extra />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/widget" element={<Widget />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </PersistGate>
          </Provider>
          <ToastContainer />
        </I18nextProvider>
      </ThemeProvider>
    </CartProvider>
  );
}

export default App;
