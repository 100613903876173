import React from "react";
import styled from "styled-components";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

const SocialIconLink = styled.a`
  color: #333;
  font-size: 24px;
  margin: 0 10px;

  &:hover {
    color: #000;
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const RoundedIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;

  &:hover {
    background-color: #aaa;
  }
`;

const SocialButtons = () => {
  return (
    <SocialIconsContainer>
      <RoundedIcon>
        <SocialIconLink
          href="https://www.facebook.com/viviterni"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </SocialIconLink>
      </RoundedIcon>
      <RoundedIcon>
        <SocialIconLink
          href="https://www.instagram.com/viviterni"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />
        </SocialIconLink>
      </RoundedIcon>
      <RoundedIcon>
        <SocialIconLink
          href="https://www.youtube.com/@viviterni5082"
          target="_blank"
          rel="noreferrer"
        >
          <FaYoutube />
        </SocialIconLink>
      </RoundedIcon>
    </SocialIconsContainer>
  );
};

export default SocialButtons;
