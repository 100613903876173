import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";
import { useTranslation } from "react-i18next";
import apiService from "../services/api.service";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ChangePasswordForm = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setUser } = require("../redux/userSlice");
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
    }
  }, [user, navigate]);
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(t("Campo obbligatorio")),
    password: Yup.string()
      .required(t("Campo obbligatorio"))
      .min(6, t("La password deve essere di 6 caratteri o più")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .test(
        "passwordMismatch",
        "E' necessario riscrivere la password quando la si sta cambiando",
        function (value) {
          const password = this.resolve(Yup.ref("password"));
          if (password && password.length > 0 && !value) {
            return false;
          }
          return true;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Change password
      const data = await apiService.changePassword(values);
      //console.log(ret);
      dispatch(setUser(data.user));
      localStorage.setItem("token", data.jwt);
      MySwal.fire({
        title: "Success!",
        text: t("Cambio password avvenuto con successo"),
        confirmButtonText: "Ok",
        imageUrl: "/black-logo.svg",
        imageWidth: 400,
        imageHeight: 200,
      });

      navigate("/profile");

      //console.log(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        gap={3}
        sx={{
          p: 3,
          borderRadius: "24px",
          bgcolor: "background.paper",
          border: "2px solid #DCDCDC",
        }}
      >
        {" "}
        <Grid item xs={12}>
          <p className="results__title">{t("Cambio password")}</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            type="password"
            label={t("Password attuale")}
            {...formik.getFieldProps("currentPassword")}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            type="password"
            label={t("Nuova password")}
            {...formik.getFieldProps("password")}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            type="password"
            label={t("Riscrivi password")}
            {...formik.getFieldProps("passwordConfirmation")}
            error={
              formik.touched.passwordConfirmation &&
              Boolean(formik.errors.passwordConfirmation)
            }
            helperText={
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            variant="search"
            type="submit"
          >
            {t("Salva")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
