import React from "react";

import AppLayout from "../components/AppLayout";

const About = () => {
  return (
    <AppLayout>
      <div>
        <h2>DIT</h2>
        <p>
          <br />
          DIT, Distretto Integrato Turistico, è una società consortile a
          responsabilità limitata costituita da soggetti privati con l’obiettivo
          di favorire la qualificazione e lo sviluppo del turismo in Umbria e
          territori limitrofi.
          <br />
          Ne fanno parte operatori turistici della ricettività, alberghiera ed
          extralberghiera, della ristorazione, dei servizi al turismo ed alcune
          associazioni sportive.
          <br />
          Il DIT è impegnato nel promuovere e, soprattutto, nel commercializzare
          i prodotti e l’offerta turistica del territorio attraverso la
          comunicazione e la diffusione di proposte turistiche strutturate.
          <br />
          Il portale web intende offrire ai potenziali turisti informazioni,
          suggerimenti di viaggio, ma anche sensazioni ed emozioni di un
          territorio capace di regalare ai visitatori entusiasmanti scoperte.
          <br />
          Navigando nel portale del DIT l’utente, sia esso un privato,
          un’agenzia o un tour operator può acquisire tutte le informazioni
          sull’offerta turistica territoriale, prenotare pernottamenti nelle
          strutture ricettive, oppure scegliere tra le varie tipologie di
          pacchetti turistici che mirano a soddisfare una vasta gamma di
          interessi e di richieste.
          <br />
          Mission
          <br />
          Sostenere lo sviluppo del settore turistico puntando sull’integrazione
          dell’offerta, sia fra le diverse tipologie di attività gestite dai
          privati, che fra le varie amministrazioni pubbliche che a diverso
          titolo hanno competenze nel settore turistico, facendo riferimento e
          utilizzando tutte le opportunità previste dalle normative (nazionali,
          comunitarie, regionali).
          <br />
          Il concetto dell’integrazione rappresenta la base su cui poggia
          l’impianto complessivo del distretto; la capacità di correlare diverse
          tipologie di “offerta turistica”, l’armonizzazione dell’attività di
          soggetti pubblici e privati, e di gestione in termini di efficienza e
          di efficacia dell’attività di presentazione/promozione del territorio
          provinciale, e la correlata commercializzazione delle strutture
          ricettive e delle altre imprese di servizi associate, costituiscono la
          filosofia in cui il DIT si deve riconoscere.
        </p>
      </div>
    </AppLayout>
  );
};

export default About;
