import React from "react";
import { useTranslation } from "react-i18next";

function Stars({ count, noempty, nostars }) {
  const { t } = useTranslation();

  if (nostars) {
    return (
      <div className="">
        {count} {t("_STARS_LC_")}
      </div>
    );
  }

  const stars = [];
  for (let i = 0; i < count; i++) {
    //if (i < count) {
    stars.push(<i key={i} className="fa fa-star star-active" />);
    /*} else {

      !noempty && stars.push(<i key={i} className="fa fa-star" />);
    }*/
  }

  return <div className="stars">{stars}</div>;
}

export default Stars;
