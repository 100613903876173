import { useState, useEffect } from "react";

import { TextField, InputAdornment } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { DatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import "moment/locale/en-gb";

import { useTranslation } from "react-i18next";

export default function Picker({
  label,
  value,
  disableDay,
  onChange,
  variant,
  fullWidth,
  icon,
  sx,
  inputSX,
}) {
  const { t: translate, i18n } = useTranslation();

  // handle language logic
  const [currentLang, setCurrentLang] = useState("it");
  useEffect(() => {
    let lang = i18n.language;
    setCurrentLang(lang);
    moment.locale(lang);
  }, [i18n.language]);

  // callback
  const handleChange = (newValue) => {
    // make sure value is not in the past
    newValue = moment(value).isSameOrAfter(moment().startOf("day"))
      ? newValue
      : moment().startOf("day");

    // console.log(moment(value).isSameOrAfter(moment().startOf("day")));
    if (onChange) onChange(moment(newValue).format("YYYY-MM-DD"));
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={currentLang}
      sx={{ width: "100%" }}
    >
      <DatePicker
        shouldDisableDate={disableDay}
        label={translate(label)}
        renderInput={(params) => (
          <TextField {...params} fullWidth={fullWidth} />
        )}
        slotProps={{
          textField: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
              sx: {
                ...inputSX,
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              },
              variant: { variant },
              readOnly: true,
              disabled: true,
            },
            placeholder: currentLang == "it" ? "GG/MM/AAAA" : "DD/MM/YYYY",
          },
        }}
        onChange={handleChange}
        value={
          moment(value).isValid() ? moment(value).locale(currentLang) : null
        }
        inputFormat="DD/MM/YYYY"
        sx={{ width: "100%", ...sx }}
        disablePast
        OpenPickerButtonProps={{ lang: currentLang }}
      />
    </LocalizationProvider>
  );
}
