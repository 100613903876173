import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import {
  Divider,
  Grid,
  IconButton,
  Dialog,
  Card,
  Typography,
  Button,
} from "@mui/material";

import Persons from "./Persons";
import ContentWrapper from "./ui/ContentWrapper";

import { useTranslation } from "react-i18next";
import { formatEuro, translateLocaleObj } from "../libs/utils";
import moment from "moment";
import { toast } from "react-toastify";
import ApiService from "../services/api.service";

const API_URL = process.env.REACT_APP_BASE_URL;
const NOT_FOUND_URL =
  "https://webhostingmedia.net/wp-content/uploads/2018/01/http-error-404-not-found.png";

function MyBookings({ bookings, reloadBookings }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const handleOpen = (item) => {
    setOpenModal(true);
    setSelectedBooking(item);
  };
  const handleClose = () => setOpenModal(false);

  const { t } = useTranslation();
  const axios = ApiService.apiClient;

  // console.log(bookings);

  const BookingCard = ({ booking, handleClose }) => (
    <Grid container p={2} className="booking-popup-card">
      <Button
        className="!absolute close-btn right-4 top-4 z-30"
        onClick={handleClose}
      >
        <img alt="close-icon" src="/images/close-btn.svg" />
      </Button>
      <Grid item xs={12} mb={3}>
        <p className="shared-title">{t("Dettaglio prenotazione")}</p>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item md={4} xs={12}>
          <Card
            className="card-img"
            sx={{ borderRadius: 8, overflow: "hidden" }}
            elevation={0}
          >
            <img
              src={API_URL + (booking.facility?.image?.url || NOT_FOUND_URL)}
              style={{ width: "100%", height: "auto" }}
            />
          </Card>
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          item
          md={7}
          xs={12}
          className="hotel-info-wrap"
        >
          <Grid item xs={12}>
            <p className="mybookings__text">
              <span className="mybookings__body">{`${t("Struttura")}:`}</span>{" "}
              {booking.facility.businessName}
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="mybookings__text">
              <span className="mybookings__body">{`${t(
                "_ARRIVAL_UCF_"
              )}:`}</span>{" "}
              {new Date(booking.checkinDate).toLocaleDateString("it-IT")}
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="mybookings__text">
              <span className="mybookings__body">{`${t(
                "_DEPARTURE_UCF_"
              )}:`}</span>{" "}
              {new Date(booking.checkoutDate).toLocaleDateString("it-IT")}
            </p>
          </Grid>
          <Grid item xs={12} className="hotel-total-price">
            <p className="mybookings__total">{`${booking.price} €`}</p>
          </Grid>
        </Grid>

        <Grid item xs={12} my={3} className="booked-title">
          <p className="shared-title">{t("Camere prenotate")}</p>
        </Grid>

        <Grid item xs={12}>
          <Grid container gap={3}>
            {booking.booking_items.map((item, i) => {
              let frozenItem = booking.frozen_data.booking_items.find(
                (frozen) => frozen.id === item.id
              );
              if (frozenItem) frozenItem = translateLocaleObj(frozenItem);
              return (
                <Grid item xs={12} key={i}>
                  <AccommodationCard
                    booking={booking}
                    booking_item={item}
                    accommodation={translateLocaleObj(item.accommodation)}
                    rate_plan={
                      booking?.coupon ? frozenItem.rate_plan : item.rate_plan
                    }
                    prezziario={
                      booking?.coupon ? frozenItem.prezziario : item.prezziario
                    }
                    treatment={
                      booking?.coupon ? frozenItem.treatment : item.treatment
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {booking?.extras?.length > 0 && (
          <>
            <Grid item xs={12} my={3} className="extra-title">
              <p className="shared-title">{t("Servizi extra prenotati")}</p>
            </Grid>

            <Grid item xs={12}>
              <Grid container gap={3}>
                {booking.extras.map((extra, i) => (
                  <Grid item md={12} xs={12} key={i}>
                    <ExtraCard
                      extra={translateLocaleObj(extra)}
                      booking={translateLocaleObj(booking)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );

  const AccommodationCard = ({
    booking,
    booking_item,
    accommodation,
    rate_plan,
    prezziario,
    treatment,
  }) => {
    const from = moment().startOf("day");
    const to = moment(booking.checkinDate).startOf("day");
    const days = to.diff(from, "days");
    // console.log(booking);

    return (
      <Card className="booked-rooms-card-wrap">
        <Grid container gap={2} p={3} className="booked-rooms-card">
          <Grid item md={3} xs={12} className="img-wrap">
            <Card
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
                width: 64,
                height: 64,
              }}
            >
              <img
                src={API_URL + (accommodation?.image?.url || NOT_FOUND_URL)}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Card>
          </Grid>
          <Grid item md={8} xs={12} container justifyContent="space-evenly">
            <Grid item md={6} xs={12}>
              <div className="title-wrap">
                <Typography variant="h5" className="title">
                  {accommodation.name}
                </Typography>{" "}
                <span>/</span>
                <Typography
                  variant="body1"
                  sx={{ mb: 2 }}
                  className="sub-title"
                >
                  {`${t("tipologia")}: ${
                    accommodation.accommodation_type.name
                  }`}
                </Typography>
              </div>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
                className="person-data-wrap"
              >
                <Grid
                  item
                  xs={3}
                  // md={1}
                  container
                  alignItems="center"
                  className="person"
                >
                  {/* <span className="per-title">Per:</span> */}
                  <Persons
                    black={
                      !!booking?.coupon
                        ? accommodation.accommodation_type.tot_guests
                        : prezziario.tot_guests
                    }
                    children={booking_item.children}
                  ></Persons>
                </Grid>
                <Grid
                  item
                  xs={7}
                  md={11}
                  container
                  alignItems="center"
                  className="treatment"
                >
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {treatment && (
                      <>
                        {`${t("trattamento")}:`} <span>{treatment.name}</span>
                      </>
                    )}
                    {!treatment && prezziario.breakfast_included && (
                      <Typography variant="body1">
                        {`${t("trattamento")}: ${t("breakfast_included")}`}
                      </Typography>
                    )}
                    {!treatment && !prezziario.breakfast_included && (
                      <Typography variant="body1">
                        {`${t("trattamento")}: ${t("room_only")}`}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12} className="services-wrap">
              <Typography variant="h5">{`${t("servizi")}:`}</Typography>
              {accommodation.accommodation_services.map((service, i) => (
                <Typography variant="body1" key={i}>
                  {` - ${service.name}`}
                </Typography>
              ))}
            </Grid>
          </Grid>

          {booking.coupon == null &&
            booking.booking_items.map((item, i) => {
              if (
                !item ||
                !item.accommodation ||
                !item.rate_plan ||
                !item.prezziario ||
                !item.treatment
              )
                return null;
              if (
                item.accommodation.id !== accommodation.id ||
                item.rate_plan.id !== rate_plan.id ||
                item.prezziario.id != prezziario.id ||
                item.treatment?.id !== treatment?.id ||
                item.children != booking_item.children
              )
                return null;

              const cancellable =
                days - rate_plan.cancel_release >= 0 &&
                item.status != "cancelled";

              return (
                <Grid className="cancell-booking" container key={i}>
                  <Card sx={{ width: "100%", p: 2 }}>
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="booking-cancell-info"
                    >
                      {item.rate_plan.refundable && (
                        <Grid item xs={8} className="room-info">
                          <Typography variant="body1" className="room-name">
                            <span className="one-x-title">
                              {`${item.quantity}X`}&nbsp;
                            </span>
                            <span className="desktop">{`${item.price} €`}</span>
                            <span className="mobile">{`${item.rate_plan.name}`}</span>
                            <span className="desktop">{` - ${
                              item.rate_plan.name
                            } - ${t("cancellabile entro")}: ${
                              item.rate_plan.cancel_release
                            } giorni`}</span>
                          </Typography>
                        </Grid>
                      )}

                      {!item.rate_plan.refundable && (
                        <Grid item xs={8} className="room-info">
                          <Typography variant="body1" className="room-name">
                            <span className="one-x-title">
                              {`${item.quantity}X`}&nbsp;
                            </span>
                            <span className="desktop">{`${item.price} €`}</span>
                            <span className="mobile">{`${item.rate_plan.name}`}</span>
                            <span className="desktop">{` - ${
                              item.rate_plan.name
                            } - ${t("cancellabile entro")}: ${
                              item.rate_plan.cancel_release
                            } giorni`}</span>
                          </Typography>
                        </Grid>
                      )}

                      <h2 className="price mobile">{item.price}€</h2>

                      {item.rate_plan.refundable && (
                        <Grid
                          item
                          xs={3}
                          md={2}
                          container
                          alignItems="center"
                          className="btn-wrap"
                        >
                          <Button
                            className="cancell-btn"
                            color="error"
                            variant="contained"
                            sx={{ mb: 2 }}
                            disabled={!cancellable}
                            onClick={async () => {
                              try {
                                await axios.put(`/cancel-booking/${item.id}`);
                                toast.success(
                                  "Prenotazione cancellata correttamente"
                                );
                              } catch (error) {
                                toast.error(error);
                              }
                              reloadBookings();
                            }}
                          >
                            {t("cancella")}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    <h2 className="mobile">{`${t("cancellabile entro")}: ${
                      item.rate_plan.cancel_release
                    } giorni`}</h2>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Card>
    );
  };

  const ExtraCard = ({ extra }) => (
    <Card className="extra-card-wrap" sx={{ borderRadius: 8, height: "auto" }}>
      <Grid container>
        <Grid className="img-wrap" item md={3} xs={12}>
          <img
            src={API_URL + (extra?.image?.url || NOT_FOUND_URL)}
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          container
          justifyContent="space-evenly"
          className="price-wrap"
        >
          <Grid item xs={11}>
            <Typography variant="h5" className="title">
              <span className="mobile">1x</span>
              {`${extra.name}`}
            </Typography>
          </Grid>

          <Grid item xs={11}>
            <Typography variant="h6" className="price">
              {`${extra.price}€`}{" "}
              <span className="desktop">{`${extra.price_type.name}`}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );

  function BookingDetail({ booking, open, handleClose }) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{ sx: { borderRadius: 8, overflow: "hidden" } }}
        className="reservation-popup-wrapper"
      >
        <Card
          sx={{
            border: "2px solid #DCDCDC",
            borderRadius: 8,
            overflow: "scroll",
          }}
        >
          <BookingCard booking={booking} handleClose={handleClose} />
        </Card>
      </Dialog>
    );
  }

  return (
    <ContentWrapper
      type="hotelInfo"
      title={t("my_bookings")}
      className="bookings-info-wrapper"
    >
      <div className="bookings-info-table-main">
        <Grid container spacing={2}>
          {/* HEADER */}
          <Grid item xs={12} container className="bookings-table-title-wrap">
            <Grid item xs={3} md={2}>
              <p className="mybookings__header">{t("Struttura")}</p>
            </Grid>
            <Grid item xs={0} md={2} display={{ xs: "none", md: "block" }}>
              <p className="mybookings__header">{t("Camere")}</p>
            </Grid>
            <Grid item xs={0} md={2} display={{ xs: "none", md: "block" }}>
              <p className="mybookings__header">{t("Servizi Extra")}</p>
            </Grid>
            <Grid item xs={3} md={2}>
              <p className="mybookings__header">{t("_ARRIVAL_UCF_")}</p>
            </Grid>
            <Grid item xs={3} md={2}>
              <p className="mybookings__header">{t("_DEPARTURE_UCF_")}</p>
            </Grid>
            <Grid item xs={3} md={2}></Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* ROWS */}
          {bookings.map((booking) => {
            return (
              <Grid
                key={booking.id}
                item
                xs={12}
                container
                className="bookings-table-wrap"
              >
                <Grid item xs={3} md={2}>
                  <p
                    className={`${
                      booking.price <= 0 && "line-through	decoration-[#DD0000]"
                    } mybookings__body`}
                  >
                    {booking.facility.businessName}
                  </p>
                </Grid>
                <Grid item xs={0} md={2} display={{ xs: "none", md: "block" }}>
                  {booking.booking_items.map((item) => {
                    let accTranslated = translateLocaleObj(item.accommodation);
                    return (
                      <div
                        key={item.id}
                        style={{
                          width: "100%",
                          overflow: "visible",
                          overflowWrap: "break-word",
                        }}
                      >
                        <p
                          className="mybookings__body"
                          style={{
                            textDecoration:
                              item.status != "cancelled"
                                ? "none"
                                : "line-through red",
                          }}
                        >
                          {item.quantity} {accTranslated.name}
                        </p>
                      </div>
                    );
                  })}
                </Grid>
                <Grid item xs={0} md={2} display={{ xs: "none", md: "block" }}>
                  {booking.booking_payments
                    .filter((item) => item.connected_resource.type == "extra")
                    .map((item) => {
                      let associatedExtra = booking.extras.find(
                        (extra) =>
                          extra.id === item.connected_resource.content.id
                      );
                      if (associatedExtra == null) return null;
                      let extraTranslated = translateLocaleObj(associatedExtra);
                      return (
                        <div key={item.id}>
                          <p className="mybookings__body">
                            {item.connected_resource.content.quantity}{" "}
                            {extraTranslated.name}
                          </p>
                        </div>
                      );
                    })}
                </Grid>
                <Grid item xs={3} md={2}>
                  <p className="mybookings__text">
                    {new Date(booking.checkinDate).toLocaleDateString("it-IT")}
                  </p>
                </Grid>
                <Grid item xs={3} md={2}>
                  <p className="mybookings__text">
                    {new Date(booking.checkoutDate).toLocaleDateString("it-IT")}
                  </p>
                </Grid>
                <Grid
                  className="total-with-icon"
                  item
                  xs={3}
                  md={2}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p className="mybookings__total">
                    {formatEuro(booking.price)}
                  </p>
                  <IconButton
                    className="icon-wrap"
                    onClick={() => handleOpen(booking)}
                  >
                    <div className="desktop">
                      <EditIcon />
                    </div>
                    <img
                      className="edit mobile"
                      src="/images/eye-icon.svg"
                      alt="edit-icon"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>

      <BookingDetail
        booking={selectedBooking}
        open={openModal}
        handleClose={handleClose}
      />
    </ContentWrapper>
  );
}

export default MyBookings;
