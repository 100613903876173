import ListLeft from "../components/ListLeft";
import UserSettings from "../components/UserSettings";
import LayoutContent from "../components/LayoutContent";

const ProfileComponent = () => {
  return (
    <LayoutContent
      SideComp={() => {
        return <ListLeft />;
      }}
      MainComp={() => {
        return <UserSettings />;
      }}
    />
  );
};

export default ProfileComponent;
