import React, { useEffect, useState } from "react";

import {
  Button,
  TextField,
  FormLabel,
  createTheme,
  ThemeProvider,
  Card,
  InputAdornment,
  Grid,
} from "@mui/material";

import GuestsSelector from "./GuestsSelector";
import DatePickerMUI from "./ui/DatePickerMUI";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  setCouponCode,
  setFrom,
  setTo,
  setAdults,
  setChildren,
} from "../redux/appSlice";
import { useDispatch } from "react-redux";
import { getAvailableHotels } from "../services/api.service";
import { normalizeData } from "../libs/utils";
import { setResults } from "../redux/resultsSlice";
import { useStore } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { setChildrenAges } from "../redux/appSlice";
import { useForm, Controller } from "react-hook-form";

const dateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M9.85 9.72502V4.02502M20.15 9.72502V4.02502M11.825 15.1875L14.0875 17.45L18.5375 13M26.25 9.37501V20.625C26.25 22.0057 25.1307 23.125 23.75 23.125H6.25002C4.8693 23.125 3.75002 22.0057 3.75002 20.625V9.37501C3.75002 7.9943 4.8693 6.87501 6.25002 6.87501H23.75C25.1307 6.87501 26.25 7.9943 26.25 9.37501Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const osiptiIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.5125 14.8625C17.2739 14.8625 19.5125 12.6239 19.5125 9.86249C19.5125 7.10106 17.2739 4.86249 14.5125 4.86249C11.7511 4.86249 9.5125 7.10106 9.5125 9.86249C9.5125 12.6239 11.7511 14.8625 14.5125 14.8625Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2 25.1C20.8564 25.0859 21.4975 24.8999 22.0593 24.5603C22.6212 24.2208 23.0841 23.7398 23.4017 23.1652C23.7193 22.5907 23.8805 21.9429 23.8693 21.2865C23.858 20.6302 23.6746 19.9883 23.3375 19.425C22.4478 18.0206 21.2175 16.8639 19.7609 16.0624C18.3043 15.2609 16.6688 14.8407 15.0063 14.8407C13.3438 14.8407 11.7082 15.2609 10.2516 16.0624C8.79508 16.8639 7.56477 18.0206 6.67503 19.425C6.33789 19.9883 6.15451 20.6302 6.14326 21.2865C6.132 21.9429 6.29324 22.5907 6.61086 23.1652C6.92849 23.7398 7.39137 24.2208 7.95322 24.5603C8.51506 24.8999 9.15619 25.0859 9.8125 25.1H20.2Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const couponIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="white"
  >
    <path
      d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0125 13.125C13.048 13.125 13.8875 12.2855 13.8875 11.25C13.8875 10.2145 13.048 9.375 12.0125 9.375C10.977 9.375 10.1375 10.2145 10.1375 11.25C10.1375 12.2855 10.977 13.125 12.0125 13.125Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2625 20.625C19.298 20.625 20.1375 19.7855 20.1375 18.75C20.1375 17.7145 19.298 16.875 18.2625 16.875C17.227 16.875 16.3875 17.7145 16.3875 18.75C16.3875 19.7855 17.227 20.625 18.2625 20.625Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8875 10.15L9.8625 20.175"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function mapStateToProps(state) {
  const { app } = state;
  return {
    stateCouponCode: app.couponCode,
    stateFrom: app.from,
    stateTo: app.to,
    stateAdults: app.adults || 1,
    stateChildren: app.children || 0,
    stateAges: app.childrenAges || [],
  };
}

function SearchForm({
  stateFrom,
  stateTo,
  stateAdults,
  stateChildren,
  stateCouponCode,
  stateAges,
  hotelInfo = false,
  simplified = false,
}) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setValue, getValues, watch, handleSubmit, control } = useForm();

  const [showOspitiBox, setShowOspitiBox] = useState(false);

  useEffect(() => {
    setValue("adults", stateAdults);
    setValue("children", stateChildren);
    setValue("couponCode", stateCouponCode);
    setValue("from", stateFrom);
    setValue("to", stateTo);
    setValue("childrenAges", stateAges);
    //console.log("Effect", stateAges, getValues("childrenAges"));
  }, [
    stateAdults,
    stateChildren,
    stateFrom,
    stateTo,
    stateCouponCode,
    stateAges,
  ]);

  const handleFormSubmit = () => {
    let adults = getValues("adults") || 1;
    let children = getValues("children") || 0;
    let ages = getValues("childrenAges");
    let couponCode = getValues("couponCode");
    let from =
      getValues("from") || moment().startOf("day").format("YYYY-MM-DD");
    let to =
      getValues("to") ||
      moment().startOf("day").add(1, "day").format("YYYY-MM-DD");

    // make sure ages is an array
    if (!Array.isArray(ages)) {
      ages = [ages];
    }

    // console.log("from", from);
    // console.log("to", to);
    // console.log("adults", adults);
    // console.log("children", children);
    // console.log("couponCode", couponCode);
    // console.log("Dispatch childrenAges", ages);

    if (hotelInfo) {
      if (id)
        window.location.href = `/hotel/${id}?from=${from}&to=${to}&adults=${adults}&children=${children}&couponCode=${couponCode}`;
      else
        window.location.href = `/?start=${from}&end=${to}&adults=${adults}&children=${children}&couponCode=${couponCode}`;
      return;
    }

    // const adults = localAdults;
    // const children = localChildren;

    dispatch(setCouponCode(couponCode));
    dispatch(setFrom(from));
    dispatch(setTo(to));
    dispatch(setAdults(adults));
    dispatch(setChildren(children));
    if (typeof ages.length !== "number") {
      dispatch(setChildrenAges([]));
    } else {
      console.log("Dispatching ages", ages);
      dispatch(setChildrenAges(ages));
    }
    getAvailableHotels(from, to, adults, children, couponCode, ages).then(
      (data) => {
        try {
          const norm = normalizeData(data);
          dispatch(setResults(norm));
        } catch (e) {
          dispatch(setResults([]));
        }
        //navigate("/");
      }
    );
  };

  const ospitiClick = (event) => {
    event.preventDefault();
    event.target.blur();
    if (!showOspitiBox) {
      setShowOspitiBox(!showOspitiBox);
    }
  };

  const guestsSelectiondone = () => {
    setShowOspitiBox(false);
    // if (!hotelInfo) {
    //   handleSubmit();
    // }
  };

  const InputSection = ({
    label,
    icon,
    type,
    name,
    value,
    defaultValue,
    onChange = null,
    onClick = null,
    onBlur = null,
  }) => (
    <>
      <FormLabel
        sx={{ color: simplified ? "black" : "white" }}
        component="legend"
      >
        {t(label)}
      </FormLabel>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }}
        value={value?.length > 0 ? value : ""}
        defaultValue={defaultValue}
        name={name}
        type={type}
        variant="outlined"
        onChange={(val) => {
          if (onChange) onChange(val);
        }}
        onBlur={(val) => {
          if (onBlur) onBlur(val);
        }}
        onClick={(val) => {
          if (onClick) onClick(val);
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        sx={{
          color: "black",
          backgroundColor: "white",
          marginBottom: 2,

          borderRadius: "5px",
          border: "1px solid #E6E6E6",

          "& .MuiInputBase-input": {
            height: 30,
            backgroundColor: "white",
            color: "black",
          },
          "& .MuiInputBase-input": {
            padding: "13px 11px 12px 11px", // Custom padding
          },
        }}
      />
    </>
  );

  const DateInputSection = ({
    label,
    icon,
    defaultValue,
    disableDay,
    onChange = null,
  }) => (
    <>
      <FormLabel
        sx={{ color: simplified ? "black" : "white" }}
        component="legend"
      >
        {t(label)}
      </FormLabel>
      <DatePickerMUI
        icon={icon}
        onChange={onChange}
        value={defaultValue}
        disableDay={disableDay}
        fullWidth
        variant="outlined"
        inputSX={{
          color: "black",
          backgroundColor: "white",
          marginBottom: 2,

          borderRadius: "5px",
          border: "1px solid #E6E6E6",

          "& .MuiInputBase-input": {
            height: 30,
            backgroundColor: "white",
            color: "black",
          },
          "& .MuiInputBase-input": {
            padding: "13px 11px 12px 11px", // Custom padding
          },
        }}
      />
    </>
  );

  let from = moment(watch("from"));
  let to = moment(getValues("to"));
  let adults = watch("adults");
  let children = watch("children");
  let ages = watch("childrenAges");
  // make sure ages is an array
  if (!Array.isArray(ages)) {
    ages = [ages];
  }

  // console.log("Rendering", ages);

  const FormContents = () => (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Controller
          name="couponCode"
          control={control}
          render={({ field }) => (
            <InputSection
              {...field}
              label="Codice coupon"
              icon={couponIcon}
              type="text"
              name="couponCode"
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} container>
          <Controller
            name="from"
            control={control}
            render={({ field }) => (
              <DateInputSection
                label={t("arrival")}
                icon={dateIcon}
                onChange={(newDate) => {
                  field.onChange(newDate);
                  if (moment(newDate).isAfter(moment(to))) {
                    field.onChange(newDate);
                  }
                }}
                defaultValue={from}
                disableDay={(day) => day < moment().startOf("day")}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="left">
          <Controller
            name="to"
            control={control}
            render={({ field }) => (
              <DateInputSection
                label={t("departure")}
                icon={dateIcon}
                onChange={(newDate) => {
                  field.onChange(newDate);
                }}
                defaultValue={to}
                disableDay={(day) =>
                  day < moment(from).add(1, "day").startOf("day")
                }
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <InputSection
          label={t("_GUESTS_")}
          icon={osiptiIcon}
          value={`${adults + children} ${t("_GUESTS_")}`}
          type="text"
          name="ospiti"
          onClick={ospitiClick}
        />
      </Grid>
      {showOspitiBox && (
        <GuestsSelector
          handleChange={({ target }) => {
            setValue(target.name, target.value);
          }}
          onDone={guestsSelectiondone}
          adults={adults || stateAdults || 1}
          children={children || stateChildren || 0}
          childrenAges={ages || stateAges || []}
        />
      )}

      <Button variant="search" fullWidth type="submit">
        {hotelInfo ? t("_MODIFICA_") : t("_SEARCH_")}
      </Button>
    </Grid>
  );

  return (
    <Grid
      item
      xs={12}
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {!simplified && (
        <Card
          sx={{
            p: 3,
            backgroundColor: "#333c56",
            borderRadius: 5,
            width: "100%",
          }}
        >
          <h1
            style={{
              fontFamily: "Inter",
              fontWeight: 700,
              marginTop: 11,
              marginLeft: 0,
              fontSize: "30px",
              marginBottom: 14,
              color: "white",
            }}
          >
            {t("_BOOK_")}
          </h1>
          <FormContents />
        </Card>
      )}

      {simplified && <FormContents />}
    </Grid>
  );
}

export default connect(mapStateToProps)(SearchForm);
