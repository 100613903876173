import { formatEuro, diffDays } from "../../libs/utils";
import { useCart } from "react-use-cart";
import { useTranslation } from "react-i18next";

function PrezzoExtra({ extra, meta, className }) {
  const { t } = useTranslation();
  const { items } = useCart();

  const numeroNotti = () => {
    return diffDays(new Date(meta.from), new Date(meta.to));
  };

  const numeroCamere = () => {
    return items.reduce((acc, item) => {
      if (!item.tmp && item.quantity > 0) {
        acc += item.quantity;
      }
      return acc;
    }, 0);
  };

  const calculateExtraPrice = () => {
    console.log(extra.quantity);
    let extraPrice = 0;
    switch (extra.price_type.type) {
      case "per_day":
        extraPrice += Number(extra.price) * numeroNotti();
        // console.log("Per Day - tot: ", extraPrice);
        break;

      case "per_room":
        extraPrice += Number(extra.price) * numeroCamere();
        // console.log("Per Room - tot: ", extraPrice);
        break;

      case "per_person":
        extraPrice += Number(extra.price) * Number(extra.quantity);
        // console.log("Per Person - tot: ", extraPrice);
        break;

      case "per_person_per_day":
        extraPrice +=
          Number(extra.price) * Number(extra.quantity) * numeroNotti();
        // console.log("Per Person Per Day - tot: ", extraPrice);
        break;

      case "per_room_per_day":
        extraPrice += Number(extra.price) * numeroCamere() * numeroNotti();
        // console.log("Per Room Per Day - tot: ", extraPrice);
        break;
    }
    return extraPrice;
  };

  return (
    <div className={className}>
      <div className="font-semibold text-red-600 text-md">
        {" "}
        {formatEuro(
          extra.quantity ? extra.price * extra.quantity : extra.price
        )}
      </div>
      <div className="text-xs font-light text-slate-500">
        {extra.price_type.type === "per_day" && (
          <span>
            ({t("_FOR_LC_")} {numeroNotti()}{" "}
            {t("_NIGHTS_LC_", { count: numeroNotti() })})
          </span>
        )}
        {extra.price_type.type === "per_room" && (
          <span>
            ({t("_FOR_LC_")} {numeroCamere()}{" "}
            {t("_ROOMS_LC_", { count: numeroCamere() })})
          </span>
        )}
        {extra.price_type.type === "per_person" && (
          <span>
            ({t("_FOR_LC_")} {extra.quantity}{" "}
            {t("_PERSONS_LC_", { count: extra.quantity })})
          </span>
        )}
        {extra.price_type.type === "per_person_per_day" && (
          <span>
            ({t("_FOR_LC_")} {extra.quantity}{" "}
            {t("_PERSONS_LC_", { count: extra.quantity })} {t("_FOR_LC_")}{" "}
            {numeroNotti()} {t("_NIGHTS_LC_", { count: numeroNotti() })})
          </span>
        )}
        {extra.price_type.type === "per_room_per_day" && (
          <span>
            ({t("_FOR_LC_")} {numeroCamere()}{" "}
            {t("_ROOMS_LC_", { count: numeroCamere() })} {t("_FOR_LC_")}{" "}
            {numeroNotti()} {t("_NIGHTS_LC_", { count: numeroNotti() })})
          </span>
        )}
        {calculateExtraPrice()}€
      </div>
    </div>
  );
}

export default PrezzoExtra;
