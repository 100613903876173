import React, { useState, useEffect, useMemo } from "react";

// mui
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  Box,
  useMediaQuery,
} from "@mui/material";

import countryList from "react-select-country-list";

// utils
import { Formik } from "formik";
import * as yup from "yup";

// hooks
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";

const CheckoutForm = ({ onFormValidChange }) => {
  const { t: translate } = useTranslation();
  const user = useSelector(selectUser);
  const isMediumScreen = useMediaQuery("(max-width: 899px)");

  const [valuesRef, setValuesRef] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    if (valuesRef) {
      localStorage.setItem("billing", JSON.stringify(valuesRef));
    }
  }, [valuesRef]);

  useEffect(() => {
    if (valuesRef) {
      // console.log("valuesRef", valuesRef);
      // initial validation
      let allValuesEntered = true;
      Object.keys(valuesRef).forEach((key) => {
        if (!valuesRef[key]) {
          allValuesEntered = false;
        }
      });
      if (onFormValidChange) onFormValidChange(allValuesEntered);
      setFormCompleted(allValuesEntered);
    }
  }, [valuesRef]);

  const countries = useMemo(() => countryList().getData(), []);

  const initialValues = {
    billing_name: user?.username || "",
    billing_email: user?.email || "",
    billing_contact: user?.mobile || "",
    codFisc: user?.codFisc || "",

    billing_address1: user?.address || "",
    country: user?.country || "",
    city: user?.city || "",
    billing_zip: user?.zipCode || "",
  };

  return (
    <Formik initialValues={initialValues} validationSchema={checkoutSchema}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        // used to retrieve billing values
        setValuesRef(values);

        return (
          <form onSubmit={handleSubmit} className="billing-form">
            <Box
              sx={{
                mb: "2rem",
                width: "100%",
              }}
              className="billing-box"
            >
              <Typography
                fontWeight="600"
                mb={2}
                className="billing-form-title"
              >
                {translate("billing_information")}
              </Typography>

              <Grid
                container
                justifyContent="space-between"
                className="billing-input-wrap"
              >
                <Grid item md={5} xs={12}>
                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="billing_name"
                    label={translate("full_name")}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billing_name || ""}
                    error={!!touched.billing_name && !!errors.billing_name}
                    helperText={touched.billing_name && errors.billing_name}
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />

                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="billing_email"
                    label={translate("email_address")}
                    type="email"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billing_email || ""}
                    error={!!touched.billing_email && !!errors.billing_email}
                    helperText={touched.billing_email && errors.billing_email}
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />

                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="billing_contact"
                    label={translate("phone_number")}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billing_contact || ""}
                    error={
                      !!touched.billing_contact && !!errors.billing_contact
                    }
                    helperText={
                      touched.billing_contact && errors.billing_contact
                    }
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />

                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="codFisc"
                    label={translate("codice_fiscale")}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.codFisc || ""}
                    error={!!touched.codFisc && !!errors.codFisc}
                    helperText={touched.codFisc && errors.codFisc}
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="country"
                    label={translate("country")}
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.country || ""}
                    error={!!touched.country && !!errors.country}
                    helperText={touched.country && errors.country}
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="billing_address1"
                    label={translate("address1")}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billing_address1 || ""}
                    error={
                      !!touched.billing_address1 && !!errors.billing_address1
                    }
                    helperText={
                      touched.billing_address1 && errors.billing_address1
                    }
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />

                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="city"
                    label={translate("city")}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city || ""}
                    error={!!touched.city && !!errors.city}
                    helperText={touched.city && errors.city}
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />

                  <TextField
                    className="input-field"
                    variant={isMediumScreen ? "outlined" : "standard"}
                    name="billing_zip"
                    label={translate("zip_code")}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billing_zip || ""}
                    error={!!touched.billing_zip && !!errors.billing_zip}
                    helperText={touched.billing_zip && errors.billing_zip}
                    InputLabelProps={isMediumScreen ? null : { shrink: true }}
                    sx={{
                      mb: "1rem",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  billing_name: yup.string().required("required"),
  billing_email: yup.string().required("required"),
  billing_contact: yup.string().required("required"),
  codFisc: yup.string().required("required"),
  billing_address1: yup.string().required("required"),
  city: yup.string().required("required"),
  country: yup.string().required("required"),
  billing_zip: yup.string().required("required"),
});
export default CheckoutForm;
