import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SocialButtons from "./SocialButtons";
import { useTranslation } from "react-i18next";
import { Logo } from "./StyledNav";
const FooterContainer = styled.footer`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 100%;
  background: #191f2b;
  color: rgba(245, 255, 255, 0.6);

  font-size: 14px;
  display: flex;

  align-items: center;
  padding: 20px 2%;

  @media (max-width: 899px) {
    align-items: center;
    display: block;
    padding: 32px 15px 28px 20px;
  }
`;

const ColumnLeft = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: 899px) {
    margin-right: 0;
    margin-bottom: 61px;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const ColumnRight = styled.div`
  flex: 4;
  display: flex;
  justify-content: space-around;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 15px;
    text-align: left;
    flex-direction: column;
    gap: 40px;
  }
  @media (max-width: 899px) {
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const Column = styled.div`
  flex: 1;
`;

const FooterLink = styled(Link)`
  color: rgba(245, 255, 255, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-decoration: none;
  margin: 16px 0px;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer className="footer">
      <ColumnLeft>
        <Logo
          className=""
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="Logo"
        ></Logo>
        <p className="footer-logo-title">Il Portale Turistico Ufficiale</p>
        <div className="social-icons desktop mt-5">
          <SocialButtons />
        </div>

        <ul className="flex items-center gap-5 footer-bottom-social ">
          <li>
            <a href="https://www.facebook.com/viviterni/" target="_blank">
              <i className="ti-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/viviterni/" target="_blank">
              <i className="ti-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@viviterni5082" target="_blank">
              <i className="ti-youtube"></i>
            </a>
          </li>
        </ul>
      </ColumnLeft>

      <ColumnRight className="footer-right-wrap">
        <Column>
          <h1>{t("info")}</h1>
          <FooterLink to={t("useful_info_link")}>{t("useful_info")}</FooterLink>
          <FooterLink to={t("privacy_link")}>
            {t("privacy_and_cookie")}
          </FooterLink>
        </Column>
        <Column>
          <h1>{t("focus_point")}</h1>
          <FooterLink to={t("must_see_link")}>{t("to_see")}</FooterLink>
          <FooterLink to={t("to_do_link")}>{t("to_do")}</FooterLink>
          <FooterLink to={t("travel_ideas_link")}>
            {t("travel_ideas")}
          </FooterLink>
          <FooterLink to={t("events_link")}>{t("events")}</FooterLink>
        </Column>
        <Column>
          <h1>{t("utility")}</h1>
          <FooterLink to={t("site_map_link")}>{t("site_map")}</FooterLink>
          <FooterLink to={t("brochure_link")}>{t("brochure")}</FooterLink>
        </Column>
        {/*

              <Column>
                   <h1>ABOUT</h1>
                  <FooterLink to="/about">{t("Chi siamo")}</FooterLink>
                  <FooterLink to="/contact">{t("Contattaci")}</FooterLink>
                  <FooterLink to="https://www.iubenda.com/privacy-policy/16102015">Privacy e cookie policy</FooterLink>
              </Column>

              <Column>
                   <h1>FOCUS POINT</h1>
                  <FooterLink to="/about">{t("Chi siamo")}</FooterLink>
                  <FooterLink to="/contact">{t("Contattaci")}</FooterLink>
                  <FooterLink to="https://www.iubenda.com/privacy-policy/16102015">Privacy Policy</FooterLink>
              </Column>

              <Column>
                   <h1>UTILITY</h1>
                  <FooterLink to="/about">{t("Chi siamo")}</FooterLink>
                  <FooterLink to="/contact">{t("Contattaci")}</FooterLink>
                  <FooterLink to="https://www.iubenda.com/privacy-policy/16102015">Privacy Policy</FooterLink>
              </Column>
    */}
      </ColumnRight>
      <div className="copy-right-wrap">
        <p className="copy-txt">
          © 2023 Portale Turistico del Comune di Terni. Designed By{" "}
          <a href="https://euromediaitalia.com">
            <u>Euromedia</u>
          </a>{" "}
          All Rights Reserved
        </p>
        <div className="footer-icons">
          <a href="https://www.comune.terni.it/" target="_blank">
            <img
              src="/images/logo-comune_di_terni.png"
              title="Logo Comune di Terni"
              className="terni-logo"
              alt="Logo Comune di Terni"
            />
          </a>
          <a href="https://turismo.comune.terni.it/it/info" target="_blank">
            <img
              src="/images/logo-iat.png"
              title="Logo Iat"
              className="iat-logo"
              alt="Logo Iat"
            />
          </a>
          <a href="https://www.umbriatourism.it/it/homepage" target="_blank">
            <img
              src="/images/logo-umbria-cuore-verde.png"
              title="Logo Umbria Cuore Verde"
              className="umbria-logo"
              alt="Logo Umbria Cuore Verde"
            />
          </a>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
