import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import HotelMarker from "./hotel-marker";
import { useState } from "react";
import MapIcon from "@mui/icons-material/Map";
import SatelliteIcon from "@mui/icons-material/Satellite";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import ContentWrapper from "./ui/ContentWrapper";

const MarkersAndFitView = ({ markers, isMobileDevice, readOnly }) => {
  const map = useMap();
  const iconHotel = new L.Icon({
    iconUrl: "/marker-icon.png",
    iconSize: [24, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -24],
  });

  useEffect(() => {
    if (map && markers.length) {
      const bounds = new L.LatLngBounds(
        markers.map((marker) => [marker.gps.lat, marker.gps.lng])
      );
      if (!isMobileDevice) {
        map.fitBounds(bounds, { padding: [300, 300] });
      } else {
        map.fitBounds(bounds);
      }
    }
  }, [map, markers]);

  return (
    <>
      {markers.map((marker) => (
        <Marker
          key={marker.id}
          position={[marker.gps.lat, marker.gps.lng]}
          icon={iconHotel}
        >
          {!readOnly && (
            <Popup>
              <HotelMarker hotel={marker} />
            </Popup>
          )}
        </Marker>
      ))}
    </>
  );
};

const MapWithMarkers = ({ hotels, readOnly }) => {
  const [isSatellite, setIsSatellite] = useState(false);

  const mapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const satelliteURL =
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

  const { t } = useTranslation();
  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };
  return (
    <ContentWrapper wrapperAdditionalStyle={{ overflow: "hidden", p: 0 }}>
      {/*<div>
                <IconButton onClick={() => setIsSatellite(false)} color={!isSatellite ? "primary" : "default"}>
                    {t('Mappa')} <MapIcon />
                </IconButton>
                <IconButton onClick={() => setIsSatellite(true)} color={isSatellite ? "primary" : "default"}>
                    {t('Satellite')} <SatelliteIcon />
                </IconButton>
    </div>*/}
      <MapContainer
        className="map-wrapper"
        style={{ width: "100%", height: "100vh" }}
        scrollWheelZoom={!isMobileDevice()}
        attributionControl={false}
        dragging={!isMobileDevice()}
        touchZoom={isMobileDevice()}
        zoomControl={!isMobileDevice()}
      >
        <TileLayer
          url={isSatellite ? satelliteURL : mapURL}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkersAndFitView
          markers={hotels}
          isMobileDevice={isMobileDevice()}
          readOnly={readOnly}
        />
      </MapContainer>
    </ContentWrapper>
  );
};

export default MapWithMarkers;
