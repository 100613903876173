import axios from "axios";
import { store } from "../redux/store";
import { addSpinner, removeSpinner } from "../redux/appSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

const MySwal = withReactContent(Swal);

const API_URL = process.env.REACT_APP_API_URL;
// check for token and set it in the header
const token = localStorage.getItem("token");

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    // When request is sent, dispatch startLoading
    store.dispatch(addSpinner());
    return config;
  },
  (error) => {
    // On error, dispatch endLoading
    store.dispatch(removeSpinner());
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    // When response is received, dispatch endLoading
    store.dispatch(removeSpinner());

    return response;
  },
  (error) => {
    MySwal.fire({
      title: "Si è verificato un errore!",
      text: "Riprova più tardi.",
      confirmButtonText: "Ok",
      imageUrl: "/black-logo.svg",
      imageWidth: 400,
      imageHeight: 200,
    });
    // On error, dispatch endLoading
    store.dispatch(removeSpinner());
    return Promise.reject(error);
  }
);

export const getExtras = async (id) => {
  try {
    const response = await apiClient.get(
      `/extras?filters[facility]=${id}&populate=deep,2`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not get extras.";
  }
};

export const getAvailableHotels = async (
  start,
  end,
  adults,
  children,
  couponCode,
  ages = []
) => {
  try {
    const response = await apiClient.get(`/available-hotels`, {
      params: {
        start,
        end,
        adults,
        children,
        couponCode,
        populate: "deep,4",
        ages,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not get strutture.";
  }
};

export const infoStruttura = async (id) => {
  try {
    const response = await apiClient.get(
      `/strutture/${id}?filters[gestore]=false&populate=deep,3`
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not get struttura.";
  }
};

export const strutturaSearch = async (
  id,
  from,
  to,
  adults = 2,
  children = 0,
  couponCode = "",
  ages = []
) => {
  from = moment(from).format("YYYY-MM-DD");
  to = moment(to).format("YYYY-MM-DD");
  try {
    const response = await apiClient.get(`/available/${id}`, {
      params: { from, to, adults, children, couponCode, ages },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not get info on struttura.";
  }
};

// Ricerca strutture
export const search = async () => {
  try {
    const response = await apiClient.get(
      "/strutture?filters[gestore]=false&populate=deep,2"
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not get strutture.";
  }
};

// AUTHENTICATION

const register = async (values) => {
  try {
    const response = await apiClient.post("/auth/local/register", values);
    localStorage.setItem("token", response.data.jwt);
    apiClient.defaults.headers["Authorization"] = `Bearer ${response.data.jwt}`;

    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not register user.";
  }
};

const login = async (identifier, password) => {
  try {
    const response = await apiClient.post("/auth/local", {
      identifier,
      password,
    });

    localStorage.setItem("token", response.data.jwt);
    apiClient.defaults.headers["Authorization"] = `Bearer ${response.data.jwt}`;

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data.message : "Could not login.";
  }
};

const changePassword = async (values) => {
  try {
    const response = await apiClient.post("/auth/change-password", values);
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data.message
      : "Could not change password.";
  }
};

// Call the /me
const me = async () => {
  try {
    const response = await apiClient.get("/users/me");
    return response;
  } catch (error) {
    logout();
    throw error.response ? error.response.data.message : "Could not login.";
  }
};

const logout = () => {
  // Remove the token from local storage and Authorization header
  localStorage.removeItem("token");
  delete apiClient.defaults.headers["Authorization"];

  // Optionally, inform the backend that the user has logged out
  //apiClient.post('/logout');
};

const apiService = {
  search,
  register,
  login,
  logout,
  me,
  changePassword,
  apiClient,
};

export default apiService;
