import React from 'react';
import { useSelector } from 'react-redux';
import { selectSpinner } from '../redux/appSlice';



const Spinner = () => {
  const spinner = useSelector(selectSpinner);

  if (spinner === 0) return null;

  return (
    <div style={{ position: 'fixed', zIndex: '2', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0)' }}>
      <i style={{ color: '#648709' }} className="fa fa-spinner fa-spin fa-3x"></i>
    </div>
  );
};

export default Spinner;
