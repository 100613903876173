import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import DatePickerMUI from "./ui/DatePickerMUI";
import GuestsSelector from "./GuestsSelector";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

import { useTranslation } from "react-i18next";

const osiptiIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.5125 14.8625C17.2739 14.8625 19.5125 12.6239 19.5125 9.86249C19.5125 7.10106 17.2739 4.86249 14.5125 4.86249C11.7511 4.86249 9.5125 7.10106 9.5125 9.86249C9.5125 12.6239 11.7511 14.8625 14.5125 14.8625Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2 25.1C20.8564 25.0859 21.4975 24.8999 22.0593 24.5603C22.6212 24.2208 23.0841 23.7398 23.4017 23.1652C23.7193 22.5907 23.8805 21.9429 23.8693 21.2865C23.858 20.6302 23.6746 19.9883 23.3375 19.425C22.4478 18.0206 21.2175 16.8639 19.7609 16.0624C18.3043 15.2609 16.6688 14.8407 15.0063 14.8407C13.3438 14.8407 11.7082 15.2609 10.2516 16.0624C8.79508 16.8639 7.56477 18.0206 6.67503 19.425C6.33789 19.9883 6.15451 20.6302 6.14326 21.2865C6.132 21.9429 6.29324 22.5907 6.61086 23.1652C6.92849 23.7398 7.39137 24.2208 7.95322 24.5603C8.51506 24.8999 9.15619 25.0859 9.8125 25.1H20.2Z"
      stroke="#8B9AAD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function OfflineRequestForm({ data }) {
  const { t } = useTranslation();
  let lang = localStorage.getItem("language", "it");

  const [showOspitiBox, setShowOspitiBox] = useState(false);
  const [requestEnabled, setRequestEnabled] = useState(false);
  const [localFirstName, setLocalFirstName] = useState();
  const [localLastName, setLocalLastName] = useState();
  const [localEmail, setLocalEmail] = useState();
  const [localPhone, setLocalPhone] = useState();
  const [localFrom, setLocalFrom] = useState(moment());
  const [localTo, setLocalTo] = useState(moment().add(1, "days"));
  const [localAdults, setLocalAdults] = useState(1);
  const [localChildren, setLocalChildren] = useState(0);
  const [localPrivacy, setLocalPrivacy] = useState(false);
  const [localPromo, setLocalPromo] = useState(false);

  const checkEnableRequest = () => {
    const check =
      localFirstName &&
      localLastName &&
      localEmail &&
      localPhone &&
      localFrom &&
      localTo &&
      localPrivacy;
    return check;
  };

  useEffect(() => {
    if (checkEnableRequest() == true) {
      setRequestEnabled(true);
    } else {
      setRequestEnabled(false);
    }

    // // update localTo to be after localFrom
    // if (moment(localTo).isBefore(localFrom)) {
    //   setLocalTo(localFrom);
    // }
  }, [
    localFirstName,
    localLastName,
    localEmail,
    localPhone,
    localFrom,
    localTo,
    localPrivacy,
  ]);

  const handleRequest = () => {
    if (checkEnableRequest() == true) {
      const body = {
        hotelID: data.id,
        firstName: localFirstName,
        lastName: localLastName,
        email: localEmail,
        phone: localPhone,
        from: localFrom,
        to: localTo,
        adults: localAdults,
        children: localChildren,
        privacy: localPrivacy,
        marketing: localPromo,
        language: lang,
      };

      console.log("Send request here with body: ", body);
      axios
        .post(`${process.env.REACT_APP_API_URL}/availability-request`, body)
        .then(toast.success("Richiesta inviata con successo"));
      //availability-request POST
    }
  };

  const handleChange = (event) => {
    console.log(event.target);
    const { name, value, checked } = event.target;
    switch (name) {
      case "firstName":
        setLocalFirstName(value);
        break;
      case "lastName":
        setLocalLastName(value);
        break;
      case "email":
        setLocalEmail(value);
        break;
      case "phone":
        setLocalPhone(value);
        break;
      case "adults":
        setLocalAdults(value);
        break;
      case "children":
        setLocalChildren(value);
        break;
      case "privacy":
        setLocalPrivacy(checked);
        break;
      case "promo":
        setLocalPromo(checked);
        break;
      default:
        // console.error("Unhandled case", event.target);
        break;
    }
  };

  const ospitiClick = (event) => {
    event.preventDefault();
    event.target.blur();
    setShowOspitiBox(!showOspitiBox);
  };

  const guestsSelectiondone = () => {
    setShowOspitiBox(false);
  };

  return (
    <Grid container className="content-wrapper contact-form-mobile">
      <Grid item xs={12}>
        <p className="content-wrapper-title">{t("request_availability")}</p>
      </Grid>

      {/* form */}
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Nome")}
            fullWidth
            name="firstName"
            value={localFirstName}
            onChange={handleChange}
            required
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Cognome")}
            name="lastName"
            fullWidth
            value={localLastName}
            onChange={handleChange}
            required
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("email")}
            name="email"
            fullWidth
            value={localEmail}
            onChange={handleChange}
            required
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("phone")}
            name="phone"
            fullWidth
            value={localPhone}
            onChange={handleChange}
            required
          ></TextField>
        </Grid>
        <Grid container justifyContent={{ xs: "space-between" }}>
          <Grid
            item
            xs={6}
            md={12}
            maxWidth={{ xs: "calc(50%)", md: "25%" }}
            className="pt-8 ps-8"
          >
            <DatePickerMUI
              label="Check-in"
              value={localFrom}
              onChange={(date) => {
                setLocalFrom(date);
              }}
              required
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={12}
            maxWidth={{ xs: "calc(50%)", md: "25%" }}
            paddingLeft={{ md: 4 }}
            className="pt-8 ps-8"
          >
            <DatePickerMUI
              label="Check-out"
              value={localTo}
              onChange={(date) => {
                setLocalTo(date);
              }}
              required
              disableDay={(date) => date < moment(localFrom)}
            />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            width={{ xs: "100%", md: "auto" }}
            maxWidth={{ xs: "100%", md: "50%" }}
            className="pt-8 ps-8"
          >
            <TextField
              label={t("guests")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{osiptiIcon}</InputAdornment>
                ),
              }}
              value={`${localAdults + localChildren} ${t("guests")}`}
              onClick={ospitiClick}
              required
            ></TextField>
            {showOspitiBox && (
              <Paper sx={{ mt: 2 }}>
                <GuestsSelector
                  handleChange={handleChange}
                  onDone={guestsSelectiondone}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* end form */}

      {/* checkboxes */}
      <Grid container mt={2} spacing={2} className="contact-checkbox-wrap">
        <Grid item xs={12}>
          <Checkbox
            size="large"
            sx={{ p: 0 }}
            name="privacy"
            checked={localPrivacy}
            onChange={handleChange}
          />
          <span className="privacy-policy-info">
            {t("privacy_policy_terms")}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            size="large"
            sx={{ p: 0 }}
            name="promo"
            checked={localPromo}
            onChange={handleChange}
          />
          <span className="privacy-policy-info">
            {t("accept_promotional_messages")}
          </span>
        </Grid>
      </Grid>

      {/* final button */}
      <Grid
        item
        xs={12}
        mt={2}
        marginTop={{ xs: 3, md: 2 }}
        className="contact-submit-btn"
      >
        <Button
          variant="contained"
          onClick={handleRequest}
          disabled={!requestEnabled}
        >
          {t("request_now")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default OfflineRequestForm;
