import AppLayout from "../components/AppLayout";

const Services = () => {
  return (
    <AppLayout>
      <div>
        <h2>Our Services</h2>
        <ul>
          <li>Service 1</li>
          <li>Service 2</li>
          <li>Service 3</li>
        </ul>
      </div>
    </AppLayout>
  );
};

export default Services;
