import React from "react";
import HotelsList from "./hotels-list";
import { Grid, Fab, Modal, Box, Typography, Button } from "@mui/material";
import MapWithMarkers from "./MapWithMarkers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectFilteredResults } from "../redux/resultsSlice";
import { DensitySmall, Place } from "@mui/icons-material";
import { useStore } from "react-redux";
import FiltersForm from "./FiltersForm";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Results({ onSearch, showEditSearch }) {
  const app = useStore().getState().app;
  const [view, setView] = React.useState("list");
  const hotels = useSelector(selectFilteredResults);

  //const filteredHotels = useSelector(selectFilteredResults);
  //console.log("filteredHotels",filteredHotels)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      {hotels.length > 0 ? (
        <Grid container justifyContent="column" alignItems="flex-start">
          <Grid item>
            <div className={!showEditSearch ? "hidden" : "search-result-wrap"}>
              <h4 className="title">Risultato della ricerca</h4>
              <div className="result-card-wrap">
                <div className="result-data-wrap">
                  <div className="title">
                    {" "}
                    <span>La tua ricerca:</span> {app.adults} {t("_ADULTS_LC_")}
                    , {app.children} {t("_CHILDREN_LC_")}
                  </div>
                  <div onClick={onSearch}>
                    <img
                      className="edit"
                      src="/images/Edit_fill.svg"
                      alt="edit-icon"
                    />
                  </div>
                </div>
                <div className="stay-time-wrap">
                  <div className="arrive-wrap booking-info">
                    <h4 className="title">Arrivo</h4>
                    <p className="date">
                      {moment(app.from).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <div className="departure-wrap booking-info">
                    <h4 className="title">Partenza</h4>
                    <p className="date">
                      {moment(app.to).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="results__title">
              {t("_SEARCH_UCF_")}: {hotels.length} {t("_STRUCTURES_FOUND_LC_")}{" "}
              <span className="text-normal">
                ({t("_FROM_LC_")} {moment(app.from).format("DD/MM/YYYY")}{" "}
                {t("_TO_LC_")} {" " + moment(app.to).format("DD/MM/YYYY")},{" "}
                {app.adults} {t("_ADULTS_LC_")}, {app.children}{" "}
                {t("_CHILDREN_LC_")})
              </span>
            </div>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ marginTop: 20 }}
            marginBottom={{ md: 0, xs: 4 }}
          >
            {/* <div className="menu-icon-wrap"> <p>Ordina</p> 
                <img alt="filter-icon" src="/images/Sort.svg" />
              </div> */}
            <div onClick={handleOpen} className="menu-icon-wrap">
              {" "}
              <p>Filtri</p>
              <img alt="filter-icon" src="/images/Filter.svg" />
            </div>
            <div onClick={() => setView("map")} className="menu-icon-wrap">
              {" "}
              <p>Mappa</p>
              <Place></Place>
            </div>
            <Fab
              className="menu-icon-wrap mobile-menu-hamburger"
              onClick={() => setView("list")}
              style={{
                marginLeft: "20px",
                maxWidth: "36px",
                boxShadow: "none",
                border: "2px solid rgba(229, 232, 243, 0.93)",
              }}
              size="small"
              sx={{ backgroundColor: "white" }}
              color=""
              aria-label="add"
            >
              <DensitySmall></DensitySmall>
            </Fab>
            <div className="desktop-filter">
              <Fab
                onClick={() => setView("list")}
                style={{
                  marginLeft: "20px",
                  boxShadow: "none",
                  border: "2px solid rgba(229, 232, 243, 0.93)",
                }}
                size="small"
                sx={{ backgroundColor: "white" }}
                color=""
                aria-label="add"
              >
                <DensitySmall></DensitySmall>
              </Fab>
              <Fab
                onClick={() => setView("map")}
                style={{
                  marginLeft: "20px",
                  boxShadow: "none",
                  border: "2px solid rgba(229, 232, 243, 0.93)",
                }}
                size="small"
                sx={{ backgroundColor: "white" }}
                color=""
                aria-label="add"
              >
                <Place></Place>
              </Fab>
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className="card">
          <Box
            className="profile-box-wrap"
            sx={{
              p: 3,
              borderRadius: "24px",
              bgcolor: "background.paper",
              border: "2px solid #DCDCDC",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "16px",
              textAlign: "center",
            }}
          >
            <div className="crd-wrapper">
              <img
                src="/black-logo.svg"
                title="Logo"
                className="logo-img"
                alt="logo-icon"
              />
            </div>
            <p className="no-data-info">
              {" "}
              Nessuna disponibilità trovata per le date indicate
            </p>
          </Box>
        </div>
      )}

      {view === "map" ? (
        <div className="map-wapper">
          <MapWithMarkers hotels={hotels} />
        </div>
      ) : (
        <HotelsList
          hotels={hotels.sort((a, b) => a.request_only - b.request_only)}
        />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="filter-popup-modal"
      >
        <Box sx={style}>
          <Button className="close-btn" onClick={handleClose}>
            <img alt="close-icon" src="/images/close-btn.svg" />
          </Button>
          <FiltersForm />
        </Box>
      </Modal>
    </Grid>
  );
}

export default Results;
