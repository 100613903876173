import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectExtras } from "../redux/appSlice";
import {
  resolveUrl,
  diffDays,
  calcolaTotaleCarrello,
  formatEuro,
} from "../libs/utils";

import Badge from "@mui/material/Badge";
import PrezzoExtra from "./ui/prezzo-extra";
import { useDispatch } from "react-redux";
import { setExtras } from "../redux/appSlice";
import { useCart } from "react-use-cart";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Grid, Divider } from "@mui/material";
import { translateOne } from "../libs/utils";

import moment from "moment";

import RemoveSvg from "./RemoveSvg";
import SearchForm from "./SearchForm";

function Carrello({
  meta,
  data,
  real = false,
  finalStep = false,
  classData,
  type = null,
  showTitle = null,
}) {
  const [editMode, setEditMode] = useState(false);

  if (typeof meta === "undefined") {
    console.error("Meta is required for carrello-new component");
  } else {
    //console.log("Meta is", meta);
  }
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    setItems,
    setCartMetadata,
    metadata,
  } = useCart();

  const app = useSelector((state) => state.app);

  const { t } = useTranslation();
  const extra = useSelector(selectExtras);
  const [numItems, setNumItems] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotoExtra = () => {
    const tmpCartItems = items
      .filter((item) => item.tmp)
      .map((item) => {
        return { ...item, tmp: false };
      });
    // Rimpiazza il carrello con il carrello temporaneo reso definitivo
    setItems(tmpCartItems);
    const meta2 = {
      hotel: data,
      from: meta.from,
      to: meta.to,
      adults: meta.adults,
      children: meta.children,
      ages: meta.ages,
    };
    setCartMetadata(meta2);
    navigate("/extras");
  };

  useEffect(() => {
    switch (real) {
      case true:
        setNumItems(
          items.reduce((acc, item) => (item.tmp ? acc : acc + item.quantity), 0)
        );
        setCartTotal(
          items.reduce(
            (acc, item) => (item.tmp ? acc : acc + item.quantity * item.price),
            0
          )
        );
        break;
      case false:
        setNumItems(
          items.reduce((acc, item) => (item.tmp ? acc + item.quantity : acc), 0)
        );
        setCartTotal(
          items.reduce(
            (acc, item) => (item.tmp ? acc + item.quantity * item.price : acc),
            0
          )
        );
        break;
      default:
        break;
    }
  }, [items]);

  const checkout = () => {
    navigate("/checkout");
  };
  const removeExtra = (e) => {
    const newExtras = extra.filter((item) => item.id !== e.id);
    dispatch(setExtras(newExtras));
  };

  const onRemoveItem = (e) => {
    removeItem(e.id);
  };

  const totale_carrello = () => {
    let from = moment(meta.from).startOf("day");
    let to = moment(meta.to).startOf("day");
    let diff = to.diff(from, "days");
    return calcolaTotaleCarrello(items, extra, real, diff, meta.adults);
  };

  return (
    <>
      <Grid
        container
        gap={3}
        sx={{
          p: 3,
          borderRadius: "24px",
          bgcolor: "background.paper",
          border: "2px solid #E5E8F3",
          position: "sticky",
          top: 40,
          left: 0,
        }}
        className="search-result-card-desktop"
      >
        <Grid item xs={12}>
          <p className="carrello__title">{t("_YOUR_BOOKING_UCF_")}</p>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={4}>
            <p className="carrello__subtitle">{t("_ARRIVAL_UCF_")}</p>
          </Grid>
          <Grid item xs={8}>
            <p className="carrello__text">
              {new Date(meta.from).toLocaleDateString("it-IT")}
            </p>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={4}>
            <p className="carrello__subtitle">{t("_DEPARTURE_UCF_")}</p>
          </Grid>
          <Grid item xs={8}>
            <p className="carrello__text">
              {new Date(meta.to).toLocaleDateString("it-IT")}
            </p>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={4}>
            <p className="carrello__subtitle">{t("_NIGHTS_UCF_")}</p>
          </Grid>
          <Grid item xs={8}>
            <p className="carrello__text">
              {diffDays(new Date(meta.from), new Date(meta.to))}
            </p>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={4}>
            <p className="carrello__subtitle">{t("_GUESTS_UCF_")}</p>
          </Grid>
          <Grid item xs={8}>
            <p className="carrello__text">
              {meta.adults ? (meta.adults != "null" ? meta.adults : 1) : 1}{" "}
              {t("_ADULTS_UCF_")} e{" "}
              {meta.children
                ? meta.children != "null"
                  ? meta.children
                  : 0
                : 0}{" "}
              {t("_CHILDREN_UCF_")}
            </p>
          </Grid>
        </Grid>

        {/* {meta.children > 0 && meta.ages && (
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <p className="carrello__subtitle">{t("_AGES_UCF_")}</p>
            </Grid>
            <Grid item xs={8}>
              <p className="carrello__text">{meta.ages.join(", ")}</p>
            </Grid>
          </Grid>
        )} */}

        {!finalStep && (
          <>
            {editMode && (
              <Grid xs={12}>
                <SearchForm hotelInfo={true}></SearchForm>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                onClick={() => setEditMode(!editMode)}
                variant="carrello"
                fullWidth
              >
                {editMode ? t("_CLOSE_") : t("_UPDATE_UCF_")}
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <p className="carrello__subtitle">
            {t("_SELECTED_ROOMS_UCF_")}{" "}
            <span className="carrello__text"> ({numItems})</span>
          </p>
        </Grid>

        {items.map((item) =>
          (real && item.tmp) || (!real && !item.tmp) ? null : (
            <Grid item xs={12} container alignItems="center">
              {/* <Grid item xs={3} container justifyContent="center">
                <Badge
                  size="large"
                  badgeContent={
                    !finalStep ? (
                      <RemoveSvg onClick={() => onRemoveItem(item)} />
                    ) : null
                  }
                >
                  <img
                  className="carrello__item-img"
                  src={
                    process.env.REACT_APP_BASE_URL +
                    item.meta?.accommodation?.image?.formats.thumbnail.url
                  }
                />
                </Badge>
              </Grid> */}
              <Grid item xs={9}>
                <p className="carrello__subtitle">
                  {item.quantity} x{" "}
                  {translateOne(item.meta?.accommodation)?.name}
                  <br></br>
                  <span className="text-xs">
                    {`${item.quantity * item.meta.prezziario.tot_guests} ${t(
                      "_PERSONE_"
                    )}${
                      item.meta.children > 0
                        ? ` + ${item.meta.children} ${t("_CHILDREN_UCF_")}`
                        : ""
                    }`}
                  </span>
                  {item.meta.treatment.id > 0 && (
                    <div>{translateOne(item.meta.treatment).name}</div>
                  )}
                </p>
                <p className="carrello__text">
                  {data ? data.businessName : metadata.hotel.businessName}
                </p>
              </Grid>
              <Grid item xs={3} textAlign="right">
                <p className="carrello__subtitle" style={{ color: "#D00" }}>
                  {formatEuro(item.quantity * item.price)}
                </p>
              </Grid>
            </Grid>
          )
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {extra.length > 0 && (
          <>
            <Grid item xs={12}>
              <p className="carrello__subtitle">{t("_EXTRA_SERVICES_")}</p>
            </Grid>

            {extra.map((extra) => (
              <Grid item xs={12} container alignItems="center">
                <Grid item xs={3} container justifyContent="center">
                  {/* <Badge
                    size="large"
                    badgeContent={
                      !finalStep ? (
                        <RemoveSvg onClick={() => removeExtra(extra)} />
                      ) : null
                    }
                  >
                    <img
                      className="carrello__item-img"
                      src={resolveUrl(extra.image?.formats.thumbnail.url)}
                    />
                  </Badge> */}
                </Grid>

                <Grid item xs={6}>
                  <p className="carrello__subtitle">
                    {translateOne(extra).name}
                  </p>
                </Grid>

                <Grid item xs={3} textAlign="right">
                  <PrezzoExtra
                    className="text-right"
                    meta={meta}
                    extra={extra}
                  />
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid item xs={12} container>
          <Grid item xs={6} textAlign="left">
            <p className="carrello__totale">{t("_TOTAL_UCF_")}</p>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <p className="carrello__totale">{formatEuro(totale_carrello())}</p>
          </Grid>
        </Grid>

        {!finalStep && real && (
          <Grid item xs={12}>
            <Button
              disabled={items?.filter((item) => item.tmp).length == 0 && !real}
              variant="search"
              onClick={real ? checkout : gotoExtra}
              fullWidth
            >
              {real ? t("_BOOK_UCF_") : t("_GO_ON_UCF_")}
            </Button>
          </Grid>
        )}
      </Grid>
      <div className="search-result-wrap">
        {editMode && (
          <Grid xs={12} mb={5} md={{ mb: 0 }}>
            <SearchForm hotelInfo={true}></SearchForm>
          </Grid>
        )}
        <h4
          className={`title ${showTitle === "showTopTitle" ? "!hidden" : ""}`}
        >
          Risultato della ricerca
        </h4>
        <div
          className={`title extra-title ${
            showTitle === "showTopTitle" ? "" : "!hidden"
          }`}
        >
          <span>La tua ricerca:</span>
          <div className="hidden">
            {meta?.adults || 1} {t("_ADULTS_UCF_")} e {meta?.children || 0}{" "}
            {t("_CHILDREN_UCF_")}
          </div>
        </div>
        <div className="result-card-wrap">
          <div
            className={`result-data-wrap ${
              showTitle === "showTopTitle" && "!hidden"
            }`}
          >
            <div className="title">
              <span>La tua ricerca:</span> {meta.adults} {t("_ADULTS_UCF_")} e{" "}
              {meta.children} {t("_CHILDREN_UCF_")}
            </div>
            <div onClick={() => setEditMode(!editMode)}>
              <img
                className="edit"
                src="/images/Edit_fill.svg"
                alt="edit-icon"
              />
            </div>
          </div>
          <div className="stay-time-wrap">
            <div
              className={`arrive-wrap booking-info ${
                items.length > 0 && "small-gap"
              }`}
            >
              <h4 className="title">Arrivo</h4>
              <p className="date">
                {new Date(meta.from).toLocaleDateString("it-IT")}
              </p>
            </div>
            <div
              className={`departure-wrap booking-info ${
                items.length > 0 && "small-gap"
              }`}
            >
              <h4 className="title">Partenza</h4>
              <p className="date">
                {new Date(meta.to).toLocaleDateString("it-IT")}
              </p>
            </div>
          </div>
          {items.length > 0 && (
            <>
              <div
                className={`${
                  !real
                    ? items.some((item) => item.tmp)
                      ? "selected-room-info-wrap"
                      : ""
                    : "selected-room-info-wrap"
                }`}
              >
                <Grid
                  item
                  xs={12}
                  className={`room-and-service-title ${
                    !real
                      ? items.some((item) => item.tmp)
                        ? ""
                        : "hidden"
                      : ""
                  }`}
                >
                  <p className="carrello__subtitle">
                    {t("_SELECTED_ROOMS_UCF_")}{" "}
                  </p>
                </Grid>
                {items.map((item) =>
                  (real && item.tmp) || (!real && !item.tmp) ? null : (
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      className="product-list-row"
                    >
                      {/* <Grid item xs={0} md={3} className="product-image">
                        <Badge
                          size="large"
                          badgeContent={
                            !finalStep ? (
                              <RemoveSvg onClick={() => onRemoveItem(item)} />
                            ) : null
                          }
                        >
                          <img
                          className="carrello__item-img"
                          src={
                            process.env.REACT_APP_BASE_URL +
                            item.meta?.accommodation?.image?.formats.thumbnail
                              .url
                          }
                        />
                        </Badge>
                      </Grid> */}
                      <Grid item xs={8} md={6}>
                        <p className="carrello__subtitle">
                          {item.quantity} x{" "}
                          {translateOne(item.meta?.accommodation)?.name}
                          <br></br>
                          <span className="text-xs person-count">
                            {`${
                              item.quantity * item.meta.prezziario.tot_guests
                            } ${t("_PERSONE_")}${
                              item.meta.children > 0
                                ? ` + ${item.meta.children} ${t(
                                    "_CHILDREN_UCF_"
                                  )}`
                                : ""
                            }`}
                          </span>
                          {item.meta.treatment.id > 0 && (
                            <div className="treatment-level">
                              {translateOne(item.meta.treatment).name}
                            </div>
                          )}
                        </p>
                        <p className="carrello__text">
                          {data
                            ? data.businessName
                            : metadata.hotel.businessName}
                        </p>
                      </Grid>
                      <Grid item xs={4} md={3} textAlign="right">
                        <p
                          className="carrello__subtitle"
                          style={{ color: "#D00" }}
                        >
                          {formatEuro(item.quantity * item.price)}
                        </p>
                      </Grid>
                    </Grid>
                  )
                )}
              </div>
            </>
          )}
          <Grid item xs={12}>
            {/* <Divider /> */}
          </Grid>
          <div className="extra-service-wrap">
            {extra.length > 0 && (
              <>
                <Grid item xs={12}>
                  <p className="carrello__subtitle extra-title">
                    {t("_EXTRA_SERVICES_")}
                  </p>
                </Grid>

                {extra.map((extra) => (
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    className="product-list-row"
                  >
                    <Grid item xs={0} md={3} className="product-image">
                      {/* <Badge
                        size="large"
                        badgeContent={
                          !finalStep ? (
                            <RemoveSvg onClick={() => removeExtra(extra)} />
                          ) : null
                        }
                      >
                        <img
                          className="carrello__item-img"
                          src={resolveUrl(extra.image?.formats.thumbnail.url)}
                        />
                      </Badge> */}
                    </Grid>

                    <Grid item xs={8} md={6}>
                      <p className="carrello__subtitle">
                        {translateOne(extra).name}
                      </p>
                    </Grid>

                    <Grid item xs={4} md={3} textAlign="right">
                      <PrezzoExtra
                        className="text-right"
                        meta={meta}
                        extra={extra}
                      />
                    </Grid>
                  </Grid>
                ))}

                <Grid item xs={12}>
                  {/* <Divider /> */}
                </Grid>
              </>
            )}
          </div>
          <Grid
            item
            xs={12}
            container
            className={`${
              type === "hotel"
                ? "!hidden"
                : type === "checkout"
                ? "checkout-total-wrap"
                : "total-bottom"
            }`}
          >
            <Grid item xs={7} textAlign="left" className="total-price-wrapper">
              <p className="carrello__totale">
                {t("_TOTAL_UCF_")}: {formatEuro(totale_carrello())}
              </p>
            </Grid>
            <Grid item xs={5} textAlign="right">
              {!finalStep && real && (
                <Grid item xs={12}>
                  <Button
                    disabled={
                      items?.filter((item) => item.tmp).length == 0 && !real
                    }
                    variant="search"
                    onClick={real ? checkout : gotoExtra}
                    fullWidth
                  >
                    {real ? t("_BOOK_UCF_") : t("_GO_ON_UCF_")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Carrello;
