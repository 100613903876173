import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiService from "../services/api.service";
import { setUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { Button, TextField, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string().required("Required"),
});

const LoginComponent = ({ checkout,setOpenPopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const data = await apiService.login(values.email, values.password);
        dispatch(setUser(data.user));
        // navigate to home or to checkout
        checkout ? navigate("/checkout") : navigate("/");
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h5 className="form-title">{t("Hai già un account? Accedi qui")}</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && t(formik.errors.email)}
            variant="outlined"
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && t(formik.errors.password)}
            variant="outlined"
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            id="password"
            label="Password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            
          />
        </Grid>
        <Grid item xs={12} className="mobile mobile-reset-password-btn">
          <Button
            color="primary"
            onClick={() => setOpenPopup(true)}
            style={{ textTransform: "none" }}
          >
            {t("Hai perso la password?")}
          </Button>
        </Grid>
        <Grid item xs={12} className="login-btn-wrap">
          <Button
            type="submit"
            variant="carrello"
            sx={{ width: { xs: 1, md: 1 / 2 } }}
            className="login-btn"
          >
            {t("Accedi")}
          </Button>
        </Grid>
        
      </Grid>
    </form>
  );
};

export default LoginComponent;
