import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { SvgIcon } from "@mui/material";

const CounterContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%", //"118px",
  height: "25px",
  borderRadius: "5px",
  border: "1px solid #E6E6E6",
  padding: "8px",
  //marginBottom: '11px',
});

const CountDisplay = styled("div")({
  margin: "0 0px",
  color: "#000",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%",
});

function Counter({
  minValue = 0,
  maxValue = 17,
  value = 0,
  onValueChange,
  label = "",
}) {
  const [count, setCount] = useState(value);

  useEffect(() => {
    setCount(Number(value)); // Update local count when the value prop changes
  }, [value]);

  const handleDecrement = () => {
    const newValue = Number(count > minValue ? count - 1 : count);
    // console.log(newValue);
    setCount(newValue);

    if (onValueChange) onValueChange(newValue);
  };

  const handleIncrement = () => {
    const newValue = Number(count < maxValue ? count + 1 : count);
    console.log(newValue);
    setCount(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <CounterContainer>
      <IconButton
        size="small"
        onClick={handleDecrement}
        disabled={count <= minValue}
      >
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M18 12L6 12" stroke="#333C56" strokeLinecap="round" />
          </svg>
        </SvgIcon>
      </IconButton>
      <CountDisplay>
        {count} {label}
      </CountDisplay>
      <IconButton
        size="small"
        onClick={handleIncrement}
        disabled={count >= maxValue}
      >
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M12 6L12 18" stroke="#333C56" strokeLinecap="round" />
            <path d="M18 12L6 12" stroke="#333C56" strokeLinecap="round" />
          </svg>
        </SvgIcon>
      </IconButton>
    </CounterContainer>
  );
}

export default Counter;
