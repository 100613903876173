export function min(v1, v2) {
  return v1 < v2 ? v1 : v2;
}

export function range(start, end, step = 1) {
  let rangeArray = [];
  if (step > 0) {
    for (let i = start; i < end; i += step) {
      rangeArray.push(i);
    }
  } else {
    // Handle negative step values
    for (let i = start; i > end; i += step) {
      rangeArray.push(i);
    }
  }
  return rangeArray;
}

export const normalizeData = (data) => {
  // following code credits goes to roelbeerens see: https://forum.strapi.io/t/discussion-regarding-the-complex-response-structure-for-rest-graphql-developer-experience/13400/9
  const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]";

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (Array.isArray(data)) {
    return data.map((item) => normalizeData(item));
  }

  if (isObject(data)) {
    if (Array.isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = normalizeData(data[key]);
    }

    return data;
  }

  return data;
};

export const translateOne = function (obj, lang = null) {
  if (!lang) {
    lang = window.localStorage.getItem("language");
    if (!lang || lang === "") lang = "it";
  }

  const _ = require("lodash");
  const new_obj = _.cloneDeep(obj);
  const localizations = obj?.localizations || [];
  for (let i = 0; i < localizations.length; i++) {
    if (localizations[i].locale === lang) {
      const localization = localizations[i];
      const keys = Object.keys(localization);
      keys.forEach((key) => {
        if (key !== "id" && key !== "localizations") {
          new_obj[key] = localization[key];
        }
      });
    }
  }
  return new_obj;
};

export const translateLocaleObj = function (obj, lang = null) {
  if (!lang) {
    lang = window.localStorage.getItem("language");
    if (!lang || lang === "") lang = "it";
  }
  const DEBUG = false;
  const _ = require("lodash");

  const originalObj = _.cloneDeep(obj);
  if (DEBUG) console.log("Original, ", originalObj);

  const fillValidData = (obj, template, translateStrings = false) => {
    let newObj = normalizeData(_.cloneDeep(obj));
    // apply keys that don't exist in the translation
    Object.keys(template).forEach((key) => {
      if (newObj[key] == null) {
        newObj[key] = template[key];
      }
    });
    // loop through the attributes and assign the values
    Object.keys(template).forEach((key) => {
      if (template[key] != null) {
        if (typeof template[key] === "object") {
          // if it is an object, we check if it is an array
          if (Array.isArray(template[key])) {
            // if the object has values, we pick it
            if (template[key].length > newObj[key].length) {
              if (DEBUG)
                console.log(
                  "Defaulting to template (array) ",
                  key,
                  template[key]
                );
              newObj[key] = template[key];
            }
          } else {
            // if the object has values, we pick it
            if (Object.keys(template[key]).length > 0) {
              if (DEBUG)
                console.log(
                  "Defaulting to template (object), ",
                  key,
                  template[key]
                );
              newObj[key] = template[key];
            }
          }
        } else if (typeof template[key] === "boolean") {
          // if the object has values, we pick it
          if (template[key] === true) {
            newObj[key] = template[key];
            if (DEBUG)
              console.log(
                "Defaulting to template (bool), ",
                key,
                template[key]
              );
          }
        }
        // we ignore the string values if they are already translated
        if (translateStrings && typeof template[key] === "string") {
          if (DEBUG)
            console.log(
              "Defaulting to template (string), ",
              key,
              template[key]
            );
          newObj[key] = template[key];
        }
      }
    });
    if (DEBUG) console.log("Translated:", newObj);
    return newObj;
  };

  // prune nested localizations
  if (obj?.localizations && obj?.localizations.data) {
    obj.localizations.data.forEach((localization) => {
      delete localization.attributes.localizations;
    });
  } else if (obj?.localizations) {
    obj.localizations.forEach((localization) => {
      delete localization.localizations;
    });
  }

  // flatten objects
  let flatObj = normalizeData({ ...obj });
  // identify the language to translate to
  let translated = flatObj.locale === lang ? { ...flatObj } : null;
  let flatTranslated = translated ? translated : null;

  if (DEBUG) console.log("Step 1", translated);
  // start localization
  if (
    flatTranslated === null &&
    flatObj.localizations !== null &&
    flatObj.localizations !== undefined
  ) {
    // loop through localizations
    if (flatObj.localizations && flatObj.localizations.length > 0) {
      if (DEBUG) console.log("Step 2", flatObj.localizations);
      //find localized data
      let localizedTemplate = flatObj.localizations.find(
        (l) => l.locale === lang
      );
      if (DEBUG) console.log("Step 4", localizedTemplate);
      if (localizedTemplate != null) {
        // build the object from relevant data
        if (DEBUG) console.log("Initial translated", originalObj);

        flatTranslated = fillValidData(originalObj, localizedTemplate, true);

        if (DEBUG) console.log("Step 5", flatTranslated);
        return flatTranslated;
      } else {
        if (DEBUG)
          if (DEBUG)
            console.log("No matching localization found for this object");
        return flatObj;
      }
    } else {
      // we have no matching localization, return the original object
      if (DEBUG) console.log("No matching localizations");
      return flatObj;
    }
  } else {
    // if we already have the localization, return it
    if (DEBUG) console.log("Already translated");
    // check for empty or defaults
    flatTranslated = { ...originalObj };
    if (!originalObj?.localizations) return flatTranslated;
    let localizedTemplate = originalObj.localizations.find(
      (l) => l.locale !== originalObj.locale
    );
    if (!localizedTemplate) return flatTranslated;
    if (DEBUG) console.log("Step 6", flatTranslated, localizedTemplate);
    flatTranslated = fillValidData(flatTranslated, localizedTemplate);
    return flatTranslated;
  }
};

export const updateFieldsWithRecordValues = (record, setValue) => {
  Object.keys(record).forEach((key) => {
    // if key is array or object, we update it
    if (
      record[key] &&
      (Array.isArray(record[key]) || typeof record[key] === "object")
    ) {
      // console.log("Updating", key);
      setValue(key, record[key]);
    }
  });
};

export const applyRecordToFields = (record, setValue) => {
  Object.keys(record).forEach((key) => {
    // console.log("Updating", key);
    setValue(key, record[key]);
  });
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

// Trasforma un url da locale a assoluto
export const resolveUrl = (url) => process.env.REACT_APP_BASE_URL + url;

// format euro in italian
export const formatEuro = (num) => {
  if (isNaN(num)){
    return "";
  }
  let formattedNumber = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  }).format(num);

  formattedNumber = formattedNumber.replace(/\s/g, "");

  return formattedNumber;
};

// iso date to italian
export const formatDate = (date) => {
  return new Intl.DateTimeFormat("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(date + "T00:00:00"));
};

export const diffDays = (date1, date2) => {
  let Difference_In_Time = date2.getTime() - date1.getTime();
  // To calculate the no. of days between two dates
  return Math.round(Difference_In_Time / (1000 * 3600 * 24));
};

export const calcolaTotaleCarrello = (items, extras, real, diff, adults) => {
  if (!items) return 0;
  let totale = 0;
  items.forEach((item) => {
    if (real && item.tmp == false) {
      totale += item.price * item.quantity;
    } else if (!real && item.tmp == true) {
      totale += item.price * item.quantity;
    }
  });

  let extraPrice = 0;
  for (let extra of extras) {
    console.log("Looking for extra", extra.id, extra.price_type.id);
    let extraType = extra.price_type;

    switch (extraType.type) {
      case "per_day":
        extraPrice += Number(extra.price) * Number(diff);
        // console.log(" - tot: ", total);
        break;

      case "per_room":
        extraPrice += Number(extra.price) * Number(items.length);
        // console.log(" - tot: ", total);
        break;

      case "per_person":
        extraPrice += Number(extra.price) * Number(adults);
        // console.log(" - tot: ", total);
        break;

      case "per_person_per_day":
        extraPrice += Number(extra.price) * Number(adults) * Number(diff);
        // console.log(" - tot: ", total);
        break;

      case "per_room_per_day":
        extraPrice += Number(extra.price) * Number(items.length) * Number(diff);
        // console.log(" - tot: ", total);
        break;
    }
  }

  totale += extraPrice;

  return totale;
};
