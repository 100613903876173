import BillingInfo from "../components/BillingInfo";
import Carrello from "../components/Carrello-new";
import LayoutContent from "../components/LayoutContent";

import { useCart } from "react-use-cart";

const Checkout = () => {
  const { metadata } = useCart();

  console.log("metadata is", metadata);
  return (
    <LayoutContent
      SideComp={() => {
        return <Carrello meta={metadata} real={true} finalStep={true} showTitle="showTopTitle" type="checkout"/>;
      }}
      MainComp={() => {
        return <BillingInfo />;
      }}
    />
  );
};

export default Checkout;
