import ExtraList from "../components/ExtraList-new";
import Carrello from "../components/Carrello-new";
import LayoutContent from "../components/LayoutContent";
import { useCart } from "react-use-cart";

const Access = () => {

  const { metadata, } = useCart();


  return (
    <LayoutContent
      SideComp={() => {
        return <Carrello meta={metadata} real={true} type="showTopTitle"/>;
      }}
      MainComp={() => {
        return <ExtraList />;
      }}
    />
  );
};

export default Access;
