import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavContainer = styled.nav`
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  z-index: 1000000000;
`;

export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: ${(props) =>
    props.isactive === "true" ? "none !important" : "none"};
  background-color: ${(props) =>
    props.isactive === "true" ? "transparent" : "transparent"};

  margin: 0 0px;
  padding: 6px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d00;
  }
`; //#f7b918;

export const Logo = styled.img`
  z-index: 1;
  height: 50px; // or adjust according to your logo's size
  margin-right: 20px;
`;

export const NavLinksContainer = styled.div`
  z-index: 9000000;
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
