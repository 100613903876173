import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';


export const RedButton = styled(Button)(({ theme }) => ({
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    backgroundColor: '#D00',
    '&:hover': {
      backgroundColor: '#F00',
    },
  }));