import React from "react";
import {
  Grid,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";

import styled from "styled-components";
import Counter from "./ui/Counter";
import { useSelector } from "react-redux";
import {
  selectAdults,
  selectChildren,
  selectChildrenAges,
} from "../redux/appSlice";
import { useTranslation } from "react-i18next";
import { range } from "../libs/utils";

const StyledTypography = styled(Typography)`
  text-align: left;
  color: #8b9aad;
  font-feature-settings: "clig" 0, "liga" 0;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

// Create a custom theme
const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      notchedOutline: {
        top: 0,
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red", // Default border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "your-hover-border-color", // Hover border color
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "your-focused-border-color", // Border color when the TextField is focused
          },
        },
      },
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: "auto", // Resets height if needed
        padding: "10px", // Adjust padding to reduce height, change as needed
        "&::placeholder": {
          color: "desired color here", // Set your desired placeholder color
          opacity: 1, // Placeholder text is usually rendered with reduced opacity; set this if you want to change it
        },
      },
    },
  },
});

const GuestsSelector = ({
  onDone,
  handleChange,
  adults = 1,
  children = 0,
  childrenAges = [],
}) => {
  // Get adults, children and childrenAges from redux store
  // const adults = useSelector(selectAdults);
  // const children = useSelector(selectChildren);
  // const childrenAges = useSelector(selectChildrenAges);

  const [ages, setAges] = React.useState([...childrenAges]);
  const [localChildren, setLocalChildren] = React.useState(children || 0);

  const { t } = useTranslation();

  const change = (type, value, index = 0) => {
    let nages = [...ages];
    switch (type) {
      case "children":
        setLocalChildren(value);
        if (nages.length < value) {
          nages.push(0);
        } else if (nages.length > value) {
          nages.splice(value, 1);
        }
        setAges(nages);
        handleChange({ target: { name: type, value } });
        break;
      case "childrenAges":
        nages[index] = Number(value);
        setAges(nages);
        handleChange({ target: { name: "childrenAges", value: nages } });
        break;
      default:
        handleChange({ target: { name: type, value } });
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        alignItems="start"
        style={{
          marginBottom: "16px",
          borderRadius: "5px",
          border: "1px solid #E6E6E6",
          marginLeft: "0px",
          marginTop: "0px",
          color: "black",
          backgroundColor: "white",
          padding: "20px",
        }}
        container
        spacing={2}
      >
        {/* Adults */}
        <Grid
          container
          alignItems="center"
          width="100%"
          //justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={5}>
            <StyledTypography variant="h6">{t("_ADULTS_")}</StyledTypography>
          </Grid>
          <Grid item xs={7}>
            <Counter
              minValue={1}
              maxValue={30}
              value={adults}
              onValueChange={(v) => change("adults", v)}
            ></Counter>
          </Grid>
        </Grid>
        {/* Children */}
        <Grid container alignItems="center" width="100%">
          <Grid item xs={5}>
            <StyledTypography variant="h6">{t("_CHILDREN_")}</StyledTypography>
          </Grid>
          <Grid item xs={7}>
            <Counter
              minValue={0}
              maxValue={10}
              value={children}
              onValueChange={(v) => change("children", v)}
            ></Counter>
          </Grid>
        </Grid>

        {/* Age Selectors */}
        {/* <Grid
          container
          style={{ marginTop: "1em" }}
          justifyContent="flex-end"
          gap={1}
        >
          {range(0, localChildren).map((index) => (
            <Grid item xs={7} key={index} spacing={0}>
              <Counter
                label={t("anni")}
                value={ages[index]}
                onValueChange={(value) => change("childrenAges", value, index)}
              ></Counter>
            </Grid>
          ))}
        </Grid> */}

        {/* Fatto Button */}
        <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
          <Button onClick={onDone} fullWidth variant="outlined">
            {t("_DONE_")}
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default GuestsSelector;
