import { useEffect } from 'react';
import { setUser } from '../redux/userSlice';
import { useDispatch } from 'react-redux';
import authService from '../services/api.service';

function Session() {
    const dispatch = useDispatch();

    useEffect(() => {
      async function me() {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            authService.apiClient.defaults.headers['Authorization'] = `Bearer ${token}`;
            const data = await authService.me();
            dispatch(setUser(data.data));
          }
        } catch (error) {
          console.error(error);
          authService.logout();
        } 
      }
      me();
    }, [dispatch]);
   
    return (
        <></>
    );


}

export default Session;