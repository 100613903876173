import React, { useState } from "react";
import Login from "./Login-new";
import Register from "./Register-new";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";
import { setUser } from "../redux/userSlice";
import ApiService from "../services/api.service";
import SearchForm from "./SearchForm";
import styled from "styled-components";
import PopupRecPassword from "./PopupRecPassword";
import { Button, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 0px;
  margintop: 79px;
  text-align: center;
  @media (min-width: 768px) {
  }
`;

const LoginOrRegister = ({ checkout = false }) => {
  const {t } = useTranslation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);

  /*if (user) {
    navigate("/");
  }*/

  return (
    <Grid
      container
      sx={{
        borderRadius: "24px",
        border: "2px solid #E5E8F3",
        bgcolor: "#FFF",
        p: 3,
      }}
      className="login-register-form-wrap"
    >
      <Grid item xs={12} className="top-login-form">
        <Register checkout={checkout} />
      </Grid>

      <Grid item xs={12} m={3} className="login-form-divider">
        <Divider />
      </Grid>

      <Grid item xs={12} className="bottom-register-form">
        <Login checkout={checkout} setOpenPopup={(data)=>setOpenPopup(data)}/>
      </Grid>

      <Grid item xs={12} className="desktop mobile-reset-password-btn">
        <Button
          color="primary"
          onClick={() => setOpenPopup(true)}
          style={{ textTransform: "none" }}
        >
          {t("Hai perso la password?")}
        </Button>
      </Grid>
      <PopupRecPassword
        title="Rec password"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      ></PopupRecPassword>
    </Grid>
  );
};

export default LoginOrRegister;
