import React, { useEffect } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import PrezzoExtra from "./ui/prezzo-extra";
import { useTranslation } from "react-i18next";
import { getExtras } from "../services/api.service";
import {
  normalizeData,
  resolveUrl,
  translateLocaleObj,
  diffDays,
  formatEuro,
  translateOne,
} from "../libs/utils";
import {
  selectExtras,
  selectLang,
  setExtras as storeSetExtras,
} from "../redux/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCart } from "react-use-cart";

function ExtraList() {
  const { t } = useTranslation();

  const lang = useSelector(selectLang);
  const [extras, setExtras] = React.useState([]);
  const [quantities, setQuantities] = React.useState({});
  const [dataReady, setDataReady] = React.useState(false);

  const { metadata, items } = useCart();

  const dispatch = useDispatch();
  const selectedExtras = useSelector(selectExtras);

  const numeroCamere = () => {
    return items.reduce((acc, item) => {
      if (!item.tmp && item.quantity > 0) {
        acc += item.quantity;
      }
      return acc;
    }, 0);
  };

  const numeroNotti = () => {
    return diffDays(new Date(metadata.from), new Date(metadata.to));
  };

  const numeroPersone = () => {
    console.warn("Calcolo numeroPersone");
    return items.reduce((acc, item) => {
      if (!item.tmp && item.quantity > 0) {
        acc += item.quantity * item.meta.prezziario.tot_guests;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    getExtras(metadata.hotel.id).then((extr) => {
      const normalizedData = extr.data.map((extra) => normalizeData(extra));
      setExtras(normalizedData);
      setDataReady(true);
    });
  }, [lang]);

  const handleChange = (extra_id, newQuantity) => {
    // Find the index of the extra in the array
    const index = selectedExtras.findIndex((extra) => extra.id === extra_id);

    // If the extra is not found, return the original array
    if (index === -1) {
      const newQuantities = { ...quantities, [extra_id]: newQuantity };
      setQuantities(newQuantities);
      return selectedExtras;
    }

    // Create a new array with the updated quantity
    const newSelectedExtras = selectedExtras.map((extra, idx) => {
      if (idx === index) {
        // Update the quantity of the found extra
        return { ...extra, quantity: newQuantity };
      }
      return extra;
    });

    dispatch(storeSetExtras(newSelectedExtras));
  };

  const addExtra = (extra) => {
    if (extraIsPresent(extra.id)) return;
    // if (extra.price_type.name === "a persona" && !quantities[extra.id]) return;
    if (extra.price_type.type === "per_person") {
      extra = { ...extra, quantity: numeroPersone() };
    }
    if (extra.price_type.type === "per_room") {
      extra = { ...extra, quantity: numeroCamere() };
    }
    if (extra.price_type.type === "per_day") {
      extra = { ...extra, quantity: numeroNotti() };
    }
    if (extra.price_type.type === "per_person_per_day") {
      extra = { ...extra, quantity: numeroPersone() * numeroNotti() };
    }
    if (extra.price_type.type === "per_room_per_day") {
      extra = { ...extra, quantity: numeroCamere() * numeroNotti() };
    }

    dispatch(storeSetExtras([...selectedExtras, extra]));
  };

  const removeExtra = (extra) => {
    const newSelectedExtras = selectedExtras.filter(
      (item) => item.id !== extra.id
    );
    dispatch(storeSetExtras(newSelectedExtras));
  };

  const extraIsPresent = (extra_id) => {
    const index = selectedExtras.findIndex((extra) => extra.id === extra_id);
    return index !== -1;
  };

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <p className="results__title">{t("_ARRICCHISCI_LA_TUA_ESPERIENZA_")}</p>
      </Grid>
      {dataReady && extras.length == 0 ? (
        <p>{t("Non ci sono extra disponibili per questa struttura")}</p>
      ) : (
        extras
          .filter((e) => e.price_type != null)
          .map((extra) => {
            return (
              <Grid
                item
                xs={12}
                container
                sx={{
                  backgroundColor: "white",
                  borderRadius: "24px",
                  border: "2px solid #e5e8f3",
                  overflow: "hidden",
                  minHeight: 200,
                }}
                className="product-card-item-wrap"
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  minHeight={{ xs: 300, md: 0 }}
                  maxHeight={{ xs: 350, md: "auto" }}
                >
                  <img
                    src={resolveUrl(extra.image?.url)}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      minHeight: "100%",
                      height: "100%",
                      objectPosition: "center",
                    }}
                  ></img>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  py={2}
                  px={3}
                  display="block"
                  spacing={2}
                  className="product-card-wrap"
                >
                  <Grid item xs={12}>
                    <p className="hotel-card__name">
                      {translateOne(extra)?.name}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="hotel-card__result">
                      {translateOne(extra)?.description}
                    </p>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  my={2}
                  sx={{ borderLeft: "1px solid #e5e8f3" }}
                  container
                  textAlign="center"
                >
                  <Grid item xs={12} className="desktop">
                    <div className="font-semibold text-red-600 text-md">
                      {" "}
                      {formatEuro(
                        extra.quantity
                          ? extra.price * extra.quantity
                          : extra.price
                      )}
                    </div>
                    <div className="text-xs font-light text-slate-500">
                      <span>{t(extra.price_type.name)}</span>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    {/** disattivato per issue cdt-76  */}
                    {false && extra.price_type.name === "a persona" && (
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select>
                          {Array(10)
                            .fill(1)
                            .map((item, index) => {
                              return (
                                <MenuItem
                                  onClick={() => handleChange(extra.id, index)}
                                  value={index}
                                >
                                  {index} (€ {extra.price * index})
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item xs={12} className="desktop">
                    {extraIsPresent(extra.id) ? (
                      <Button
                        onClick={() => {
                          removeExtra(extra);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        {t("_REMOVE_UC_")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          addExtra(extra);
                        }}
                        variant="bookNow"
                      >
                        {t("_AGGIUNGI_UC_")}
                      </Button>
                    )}
                  </Grid>

                  <div className="mobile-price-btn">
                    <div className="price-btn-wrap">
                      <div className="font-semibold text-red-600 text-md price-title">
                        {" "}
                        {formatEuro(
                          extra.quantity
                            ? extra.price * extra.quantity
                            : extra.price
                        )}
                      </div>
                      <div className="text-xs font-light text-slate-500 semi-title">
                        <span className="mobile-slash">/</span>
                        <span>{t(extra.price_type.name)}</span>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      {extraIsPresent(extra.id) ? (
                        <Button
                          onClick={() => {
                            removeExtra(extra);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          {t("_REMOVE_UC_")}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            addExtra(extra);
                          }}
                          variant="bookNow"
                        >
                          {t("_AGGIUNGI_UC_")}
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            );
          })
      )}
    </Grid>
  );
}

export default ExtraList;
