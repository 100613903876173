import React from "react";
import { Button } from "@mui/material";
import { Carousel } from "@material-tailwind/react";

import { useTranslation } from "react-i18next";
import { translateOne } from "../libs/utils";

const RoomCard = ({ room, onClose }) => {
  const { t } = useTranslation();
  console.log("room", room);
  const { gallery, image } = room;
  const updatedGallery = Array.isArray(gallery) ? gallery : [];

  if (image && image !== null && image !== undefined) {
    updatedGallery.push(image);
  }

  const getServicesTypes = () => {
    let services = [];
    room?.accommodation_services.map((service) => {
      if (!services.includes(service.facility_service_type)) {
        services.push(service.facility_service_type);
      }
    });
    return services;
  };

  const getServicesByCategoryId = (categoryId) => {
    return room?.accommodation_services.filter(
      (service) => service.facility_service_type.id === categoryId
    );
  };
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)", // 0.5 sets 50% transparency
        zIndex: 1001,
      }}
      onClick={() => onClose}
    >
      <div
        className="room-service-popup-wrap"
        style={{
          width: "calc(50% + 34px)",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "0px 12px 12px 12px",
          zIndex: 1002,
          borderRadius: "16px",
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "8px",
            left: "8px",
            textAlign: "right",
          }}
          className="close-btn-popup"
        >
          <Button
            onClick={onClose}
            style={{
              margin: 0,
              padding: 0,
              background: "transparent",
              border: "none",
            }}
          >
            <svg
              className="desktop-close-btn"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M17.65 12.35L12.35 17.65M17.65 17.65L12.35 12.35M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                stroke="#2D3954"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <svg
              className="mobile-close-btn"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.625 13.5C26.625 20.7487 20.7487 26.625 13.5 26.625C6.25126 26.625 0.375 20.7487 0.375 13.5C0.375 6.25126 6.25126 0.375 13.5 0.375C20.7487 0.375 26.625 6.25126 26.625 13.5ZM6.95956 20.0404C6.56904 19.6499 6.56904 19.0168 6.95956 18.6262L12.0858 13.5L6.95956 8.37377C6.56904 7.98325 6.56904 7.35008 6.95956 6.95956C7.35008 6.56904 7.98325 6.56904 8.37377 6.95956L13.5 12.0858L18.6262 6.95956C19.0168 6.56904 19.6499 6.56904 20.0404 6.95956C20.431 7.35008 20.431 7.98325 20.0404 8.37377L14.9142 13.5L20.0404 18.6262C20.431 19.0168 20.431 19.6499 20.0404 20.0404C19.6499 20.431 19.0168 20.431 18.6262 20.0404L13.5 14.9142L8.37377 20.0404C7.98325 20.431 7.35008 20.431 6.95956 20.0404Z"
                fill="#E5E8F3"
              />
            </svg>
          </Button>
        </div>
        <div className="room-details__container ">
          <div className="mobile-popup-room-detail">
            <div className="popup-title">
              <p className="shared-title">{room.name}</p>
              <p className="mt-3 shared-subtitle ">{room.description}</p>
            </div>
            <div className="gallery-slider-wrap">
              <Carousel
                navigation={false}
                autoplay={true}
                className="slider-img-wrap"
              >
                {updatedGallery?.map((image, i) => (
                  <img
                    alt={`Image${i}`}
                    key={i}
                    src={
                      process.env.REACT_APP_BASE_URL + image?.formats.small.url
                    }
                    className="object-cover w-full h-full"
                  />
                ))}
              </Carousel>
            </div>
            <div className="service-detail-wrap">
              {room.accommodation_services.length > 0 && (
                <div className="flex flex-col">
                  <h1 className="my-3 text-slate-700 service-title">
                    {t("Servizi")}
                  </h1>
                  <h3 className="service-item-title">Categoria servizio</h3>
                  <div>
                    <ul className="two-column-list service-list-wrap">
                      {room.accommodation_services.map((service, i) => {
                        service = translateOne(service);
                        return (
                          <li key={i} className="font-thin">
                            <span>
                              <svg
                                width="16"
                                height="13"
                                viewBox="0 0 16 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.79167 8.83333L4.87898 11.0481C5.30089 11.3508 5.88428 11.2802 6.22181 10.8856L14.25 1.5"
                                  stroke="#33363F"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </span>
                            <span className="pl-2 text-xs font-normal text-slate-500 list-item-title">
                              {service.name}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="prohibited-wrap">
                    <p>
                      <span className="title">Fumatori:</span> è vietato fumare
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="desktop-popup-detail">
            <div>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_BASE_URL +
                    room?.image?.formats.small.url
                  }
                />
              </div>
              <div className="hotel-info__thumbnails">
                {room?.gallery?.map((image, i) => (
                  <img
                    alt=""
                    key={i}
                    src={
                      process.env.REACT_APP_BASE_URL + image?.formats.small.url
                    }
                  />
                ))}
              </div>
            </div>
            <div>
              <div>
                <p className="shared-title">{room.name}</p>
                <p className="mt-3 shared-subtitle ">{room.description}</p>
              </div>
              {room.accommodation_services.length > 0 && (
                <div className="flex flex-col">
                  <h1 className="my-3 text-slate-700">{t("Servizi")}</h1>
                  <div>
                    <ul className="two-column-list">
                      {room.accommodation_services.map((service, i) => {
                        service = translateOne(service);
                        return (
                          <li key={i} className="font-thin">
                            <span>✔</span>
                            <span className="pl-2 text-xs font-normal text-slate-500">
                              {service.name}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
