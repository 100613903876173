import { Button, TextField, Grid } from "@mui/material";
import LayoutContent from "../components/LayoutContent";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apiService from "../services/api.service";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <LayoutContent
      SideComp={() => {
        return <></>;
      }}
      MainComp={() => {
        return (
          <Grid
            item
            xs={6}
            container
            gap={3}
            sx={{
              p: 3,
              borderRadius: "24px",
              bgcolor: "background.paper",
              border: "2px solid #DCDCDC",
            }}
            component="form"
            onSubmit={(e) => {
              e.preventDefault();

              let code = new URLSearchParams(window.location.search).get(
                "code"
              );

              let password = e.target.password.value;
              let passwordConfirmation = e.target.passwordConfirmation.value;
              console.log(password, passwordConfirmation, code);

              if (password !== passwordConfirmation) {
                toast.error(t("passwords_match_error"));
                return;
              }
              if (!code) {
                toast.error(t("password_code_error"));
                return;
              }

              try {
                const data = apiService.apiClient
                  .post(
                    `${process.env.REACT_APP_API_URL}/auth/reset-password`,
                    {
                      password,
                      passwordConfirmation,
                      code,
                    }
                  )
                  .then((data) => {
                    toast.success(t("reset_password_success"));
                    navigate("/access");
                  });
              } catch (error) {
                console.error(error);
                toast.error(t("reset_password_error"));
              }
            }}
          >
            <Grid item xs={12}>
              <p className="results__title">{t("Reset password")}</p>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                name="password"
                label={t("Nuova password")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                name="passwordConfirmation"
                label={t("Riscrivi password")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="search" type="submit">
                {t("Salva")}
              </Button>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default ResetPassword;
